<style lang="scss" scoped>
    .mod-driver-confirm /deep/ {
        .add-dialog-info {
            min-height: 50px;
            max-height: 280px;
            overflow: hidden;
            overflow-y: scroll;
        }
    }

    .del-info-tips-icon {
        position: absolute;
        left: 0;
        top: -1px;
        font-size: 22px;
    }

</style>
<template>

    <div>
        <el-dialog title=""
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="delDialogShow">
            <div class="add-dialog-info" v-if="delInfoDialog">
                <div style="padding-left: 35px;position: relative;">
                    <div v-if="orderNotFinished && orderNotFinished.length > 0">
                        <div class="del-info-tips-icon">
                            <i class="el-icon-error" style="color: #F5222D"></i>
                        </div>
                        <div style="font-weight:600;margin-bottom: 15px">订单未完成</div>
                        {{(addType === 1 ? '司机' : '车辆')}}
                        <span style="color: #FF3B30" v-if="addType === 1">
                            {{delInfoDialog.driverName}}
                            ({{delInfoDialog.driverMobile}})
                        </span>
                        <span style="color: #FF3B30" v-else>
                            {{delInfoDialog.carNumberPlate}}
                            {{delInfoDialog.carBrand}}
                            {{delInfoDialog.carModel}}
                            <span style="width: 80px;">
                                {{delInfoDialog.carType | seatModelDesc}}
                            </span>
                            {{delInfoDialog.carColor}}
                        </span>目前有以下未完成订单，<span
                            style="color: #FF3B30">无法删除</span>：
                        <ul>
                            <li v-for="(list, index) in orderNotFinished" :key="index">
                                订单号：{{list.orderNo}}
                                订单状态：{{list.orderStatus | orderStatusDesc}}
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <div class="del-info-tips-icon">
                            <i class="el-icon-warning" style="color: #FAAD14"></i>
                        </div>
                        <div style="font-weight:600;">
                            确认要删除{{(addType === 1 ? '司机' : '车辆')}}：
                            <span style="color: #FF3B30" v-if="addType === 1">
                                {{delInfoDialog.driverName}}
                                ({{delInfoDialog.driverMobile}})
                            </span>
                            <span style="color: #FF3B30" v-else>
                                {{delInfoDialog.carNumberPlate}}
                                {{delInfoDialog.carBrand}}
                                {{delInfoDialog.carModel}}
                                <span>
                                    {{delInfoDialog.carType | seatModelDesc}}
                                </span>
                                {{delInfoDialog.carColor}}
                            </span>？
                        </div>

                    </div>
                </div>
            </div>


            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取 消</el-button>
                <el-button size="small" type="primary" @click="handleDelSubmit()">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import {seatModelList, orderStatusList} from "@/data/index";

    export default {
        //定义模版数据
        data() {
            return {
                seatModelList,
                orderStatusList,
                list: []
            }
        },
        props: {
            addType: {
                type: Number,
                default: null
            },
            delDialogShow: {
                type: Boolean,
                default: false
            },
            delInfoDialog: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            orderNotFinished: {
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        components: {},
        filters: {
            orderStatusDesc(value) {
                let ret = orderStatusList.find((item) => item.value === value) || {}
                return ret.name
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            handleCancel() {
                this.$emit('update:getCancel', {type: this.addType, show: false});
            },
            handleDelSubmit() {
                if (this.orderNotFinished && this.orderNotFinished.length > 0) {
                    this.handleCancel();
                    return true
                } else {
                    this.$emit('update:getDelSelect', {type: this.addType, delInfo: this.delInfoDialog})
                }
            },
        },
        //监听模版变量
        watch: {}

    }
</script>

