<style lang="scss" scoped>
    .mod-driver-confirm /deep/ {
        .add-dialog-info {
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
        }

        .add-dialog-info_list {
            span {
                display: inline-block;
            }

            .list1 {
                width: 80px;
            }

            .list2 {
                width: 80px;
            }

            .list3 {
                width: 80px;
            }

            .list4 {
                width: 50px;
            }
        }

        .el-checkbox {
            line-height: 22px;
            margin-right: 0;
            margin-bottom: 4px;
            display: block;

            .el-checkbox__label {
                display: inline-block;
            }
        }
    }

</style>
<template>

    <div>
        <el-dialog :title="'添加'+ (addType === 1 ? '司机' : '车辆')"
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="addDialogShow">
            <div class="add-dialog-info">
                <el-checkbox-group v-model="addSelectList" v-if="list && list.length > 0">
                    <!--                    @change="changeAddSelect"-->
                    <el-checkbox v-for="(item, index) in list" :key="index" :label="item.id"
                                 :value="item.id">
                        <span class="add-dialog-info_list" v-if="addType === 1">
                            <span class="list1">{{item.driverName}}</span>
                            <span class="list2">{{item.driverMobile}}</span>
                        </span>
                        <span class="add-dialog-info_list" v-if="addType === 2">
                            <span class="list1">{{item.carNumberPlate}}</span>
                            <span class="list2">{{item.carBrand}} {{item.carModel}}</span>
                            <span class="list3">{{item.carType | seatModelDesc}}</span>
                            <span class="list4">{{item.carColor}}</span>
                        </span>
                    </el-checkbox>
                </el-checkbox-group>
                <div v-else>
                    当前无{{(addType === 1 ? '司机' : '车辆')}}可选
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取 消</el-button>
                <el-button size="small" type="primary" @click="changeAddSelect()">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import {seatModelList} from "@/data/index";

    import fleetDetailServer from '../../../../../urls/fleetDetailUrls'

    export default {
        //定义模版数据
        name: "addInfoDialog",
        data() {
            return {
                addSelectList: [],
                seatModelList,
                list: null
            }
        },
        props: {
            addType: {
                type: Number,
                default: null
            }, // 1 司机  2车辆
            addDialogShow: {
                type: Boolean,
                default: false
            }, // 弹窗是否显示
            supplierId: {
                type: String,
                default: ''
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            handleCancel() {
                this.$emit('update:getCancel', {type: this.addType, show: false});
                this.addSelectList = []
            },  // 取消
            changeAddSelect() {
                let selectList = [];
                this.addSelectList.forEach(item => {
                    this.list.forEach(list => {
                        if (list.id == item) {
                            selectList.push(list)
                        }
                    })
                });
                if (selectList && selectList.length > 0) {
                    this.$emit('update:getAddSelect', {type: this.addType, selectList})
                } else {
                    this.$message.error('请选择' + (this.addType === 1 ? '司机' : '车辆'));
                }
            },  // 选中司机或车辆
            async handleInfoList(type) {
                this.loadingShow();
                let res,
                    form = {};
                form.supplierId = this.supplierId
                if (type === 1) {
                    res = await fleetDetailServer.supplierDriverFleetDriverInfo(this, form);
                } else {
                    res = await fleetDetailServer.supplierDriverFleetCarInfo(this, form);
                }

                if (res && res.success) {
                    this.list = res.data;
                }

                this.loadingHide()
            },  // 获取司机或车辆列表
            delFleetId(id){
                if(id){
                    this.addSelectList.forEach((item, index) => {
                        if(item == id){
                            this.addSelectList.splice(index, 1)
                        }
                    })
                }
            }

        },
        //监听模版变量
        watch: {}

    }
</script>

