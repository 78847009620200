<style lang="scss" scoped>

</style>
<template>
    <div>
        <el-form :inline="true" :model="form" size="small">
            <el-form-item>
                <SelectCity ref="selectCityRef" :is-border="false"
                            @update:city="handleCityChange"></SelectCity>
            </el-form-item>
            <el-form-item label="">
                <el-input v-model="form.idCardNumber" placeholder="输入身份证" clearable @change="onChange"></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-input v-model="form.carNumberPlate" placeholder="输入车牌号" clearable
                          @change="onChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="reset">重置</el-button>
                <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
        </el-form>
    </div>

</template>

<script type="text/ecmascript-6">
import SelectCity from "@/components/selectCity/index.vue";

export default {
    //定义模版数据
    data() {
        return {
            form: {
                idCardNumber: '',
                carNumberPlate: '',
                reason: ''
            },
        }
    },
    components: {
        SelectCity
    },
    //定义事件方法
    methods: {
        onChange() {
            this.$emit('change', this.form)
        },
        reset() {
            this.form = {
                idCardNumber: '',
                carNumberPlate: '',
                reason: '',
                cityId: ''
            }
            this.onChange();
            this.search();
            this.$refs['selectCityRef'].clear()
        },
        search() {
            this.$emit('onSearch')
        },
        handleCityChange(city) {
            this.form.cityId = city.shortCode
            this.onChange()
        }
    }

}
</script>

