<template>
    <el-popover placement="left" width="800" trigger="click" title="司机登录日志" @show="getData">
        <el-table :data="result.data" v-loading="loading" max-height="400px">
            <el-table-column width="60" label="序号" align="center" type="index"></el-table-column>
            <el-table-column property="loginName" align="center" label="司机姓名"></el-table-column>
            <el-table-column property="mobile" align="center" label="手机号"></el-table-column>
            <el-table-column property="source" align="center" label="来源"></el-table-column>
            <el-table-column width="170" align="center" property="address" label="位置">
                <template slot-scope="scope">
                    <el-link type="primary"
                             :href="`https://uri.amap.com/marker?position=${scope.row.lng},${scope.row.lat}`"
                             target="_blank" v-if="scope.row.lat"> {{ scope.row.lat }},{{ scope.row.lng }}
                    </el-link>
                    <p v-else>未知</p>
                </template>
            </el-table-column>
            <el-table-column width="160" align="center" property="createTime" label="登录时间">
                <template slot-scope="scope">
                    {{ scope.row.createTime|dateCus }}
                </template>
            </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: flex-end;align-items:center;height: 50px">
            <el-pagination size="mini" @current-change="handleCurrentChange"
                           :current-page="pageIndex"
                           :page-size="pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="result.total">
            </el-pagination>
        </div>
        <el-button slot="reference" type="text">登录日志</el-button>
    </el-popover>
</template>

<script>

import {getDriverLoginLogApi} from "../../../urls/driverManage";

export default {
    name: "LoginLogLoginLogPopover",
    data() {
        return {
            loading: false,
            pageSize: 10,
            pageIndex: 1,
            result: {
                data: [],
                total: 0
            }
        }
    },
    props: {
        driverInfo: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async getData(index = 1) {
            this.pageIndex = index;
            this.loading = true;
            const ret = await getDriverLoginLogApi({
                id: this.driverInfo.id,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            })
            if (ret.success && ret.data) {
                this.result = ret.data
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        },
        handleCurrentChange(index) {
            this.getData(index);
        }
    }
}

</script>

<style scoped>

</style>
