<script>
import {exportNotQualifiedDriverApi, getNotQualifiedDriverApi} from "@/www/urls/driverManage";
import comListSearch from "@/www/components/comListSearch/index.vue";
import moment from "moment";

export default {
    name: "NonCompliant",
    components: {comListSearch},
    data() {
        return {
            loading: false,
            form: {
                pageIndex: 1,
                pageSize: 10
            },
            dataSource: [],
            total: 0
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getForm() {
            const f = {...this.form}
            if (f.type === '0')
                f.vType = '不合规'
            if (f.type === '1')
                f.iType = '不合规'
            if (f.date) {
                f.startCreateTime = moment(f.date[0]).startOf('d').valueOf()
                f.endCreateTime = moment(f.date[1]).endOf('d').valueOf()
            }
            return f
        },
        async getList() {
            this.loading = true
            try {
                const ret = await getNotQualifiedDriverApi(this.getForm())
                if (ret.success && ret.data) {
                    this.dataSource = ret.data.data
                    this.total = ret.data.total
                }
            } finally {
                this.loading = false
            }
        },
        reset() {
            this.form = {
                pageIndex: 1,
                pageSize: 10
            }
            this.getList()
        },
        async handleExport() {
            this.loadingShow();
            const ret = await exportNotQualifiedDriverApi(this.getForm())
            this.loadingHide();
            let d = new Date().toLocaleString();
            let a = document.createElement('a');
            a.download = '不合规司机车辆列表' + d + '.xls';
            a.href = window.URL.createObjectURL(ret);
            a.click();
        }
    }
}
</script>

<template>
    <div v-loading="loading">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="fleet-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form inline size="small">
                            <el-form-item>
                                <el-input placeholder="订单号" v-model="form.orderId" clearable/>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="form.type" clearable placeholder="不合规类型">
                                    <el-option value="0" label="车辆">车辆</el-option>
                                    <el-option value="1" label="司机">司机</el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="车牌号" v-model="form.vehicleNo" clearable/>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="车牌号" v-model="form.licenseId" clearable/>
                            </el-form-item>
                            <el-form-item>
                                <el-date-picker
                                    v-model="form.date"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期" clearable>
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" style="text-align: right">
                        <el-button @click="getList()" type="primary">搜索</el-button>
                        <el-button @click="reset()" type="primary" plain>重置</el-button>
                        <el-button @click="handleExport()">导出</el-button>
                    </div>
                </com-list-search>
                <el-table :data="dataSource">
                    <el-table-column label="订单号" prop="orderId"></el-table-column>
                    <el-table-column label="司机" prop="vType">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.iType==='不合规'?'danger':'success'" v-if="scope.row.iType">
                                {{ scope.row.iType }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="车辆" prop="vType">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.vType==='不合规'?'danger':'success'" v-if="scope.row.vType">
                                {{ scope.row.vType }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="车牌号" prop="vehicleNo"></el-table-column>
                    <el-table-column label="证件号" prop="licenseId"></el-table-column>
                    <el-table-column label="时间" prop="createTime">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | dateCus }}
                        </template>
                    </el-table-column>
                </el-table>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="getList"
                        :current-page.sync="form.pageIndex"
                        :page-size="form.pageSize"
                        layout="total, prev, pager, next"
                        :total="total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
    </div>
</template>

<style scoped lang="scss">
.fleet-list {
    &::v-deep {
        .list-search-after {
            width: 240px;
        }
    }
}
</style>
