<style lang="scss" scoped>
.fleet-list {
    .fleet-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .fleet-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }
}

.fleet-list /deep/ {
    .common-list-search {
        padding-bottom: 20px;

        .list-search-before {
            .el-form-item.el-form-item--small {
                width: auto;
                /*min-width: 320px;*/
                margin-bottom: 0;
            }

            .el-form-item__content {
                width: auto;
            }

        }

        .list-search-after {

        }
    }

    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }
}
</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.fleet-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}


</style>
<template>
    <div v-loading="listLoading">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="fleet-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <fleet-list-search @change="form=$event" @onSearch="onRefresh"></fleet-list-search>
                    </div>
                    <div slot="list-search-after" style="text-align: right">
                        <el-button @click="handleAddFleet()" type="primary" style="width: 120px">添加黑名单</el-button>
                    </div>
                </com-list-search>
                <fleet-list-table style="margin-top: 10px" :fleetList="fleetList"
                                  @onDel="handleDel">
                </fleet-list-table>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="searchList"
                        :current-page.sync="pageInfo.pageIndex"
                        :page-size="pageInfo.pageSize"
                        layout="total, prev, pager, next"
                        :total="pageInfo.total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>

        <add-dialog
            ref="addDialogRef"
            @onRefresh="onRefresh">
        </add-dialog>
    </div>
</template>


<script type="text/ecmascript-6">
import comListSearch from "../../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";

import fleetListTable from "./components/fleetListTable/index"
import AddDialog from "./components/add-dialog/index"
import fleetListSearch from "./components/fleetListSearch/index"
import {getBlackListApi, updateBlackListApi} from "@/www/urls/driverManage";

export default {
    //定义模版数据
    name: 'DriverBlacklist',
    data() {
        return {
            addDialogShow: false,
            form: {},
            fleetList: [],
            listLoading: false,
            pageInfo: {
                // 列表分页
                pageSize: 10, // 每页显示条目个数
                total: 1, // 总条目个数
                pageIndex: 1 // 当前页数
            }
        };
    },
    components: {
        SectionContent,
        comListSearch,
        fleetListTable,
        AddDialog,
        fleetListSearch
    },
    activated() {
        this.onRefresh()
    },
    mounted() {
        this.onRefresh()
    },
    //定义事件方法
    methods: {
        onRefresh() {
            this.searchList(1)
        },
        async handleDel({id}) {
            try {
                await this.$confirm('确定删除！', '提示')
                this.listLoading = true
                let form = {
                    isDelete: 1,
                    id
                };
                let res = await updateBlackListApi(form);
                if (res.success) {
                    this.$message.success("删除成功!");
                    this.searchList(this.pageInfo.pageIndex)
                }
            } finally {
                this.listLoading = false
            }
        },
        searchFleetInfo() {
            this.searchList(1)
        },
        async searchList(index) {
            try {
                this.listLoading = true
                let form = {
                    pageIndex: index,
                    pageSize: this.pageInfo.pageSize,
                    idCardNumber: this.form.idCardNumber || undefined,
                    carNumberPlate: this.form.carNumberPlate || undefined,
                    cityId: this.form.cityId || undefined,
                }
                let res = await getBlackListApi(form);
                if (res.success && res.data) {
                    this.fleetList = res.data.data;
                    this.pageInfo.total = res.data.total;
                }
            } finally {
                this.listLoading = false

            }
        },
        handleAddFleet() {
            this.$refs['addDialogRef'].open()
        }
    },
    //监听模版变量
    watch: {}
};
</script>

