// 查询所属供应商无车队司机
const supplierDriverFleetDriverInfo = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/supplierDriverFleetDriverInfo', form, "POST");
};

// 查询所属供应商无车队车辆
const supplierDriverFleetCarInfo = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/supplierDriverFleetCarInfo', form, "POST");
};

// 创建车队
const driverFleetCreate = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/driverFleetCreate', form, "POST");
};

// 编辑车队信息
const driverFleetUpdate = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/driverFleetUpdate', form, "POST");
};

// 车队信息列表
const driverFleetPageList = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/driverFleetPageList', form, "POST");
};

// 车队启用停用
const driverFleetUpdateUseStatus = async (_this, form) => {
    return await _this.http('/galaxyDriverFleetApi/driverFleetUpdateUseStatus', form, "POST");
};

// 车队信息详情
const driverFleetDetail = async (_this, driverFleetId) => {
    return await _this.http(`/galaxyDriverFleetApi/driverFleetDetail/${driverFleetId}`);
};

// 根据司机信息Id车牌号查询订单信息   {"carNumberPlate": "string","driverGuiderNo": "string"}
const selectOrderInfoByDriverAndCar = async (_this, form) => {
    return await _this.http('/galaxyOrder/selectOrderInfoByDriverAndCar', form, "POST");
};

// 车队操作日志
const selectDriverFleetLog = async (_this, driverFleetId) => {
    return await _this.http(`/galaxyDriverFleetApi/selectDriverFleetLog/${driverFleetId}`);
};


export default {
    supplierDriverFleetDriverInfo,
    supplierDriverFleetCarInfo,
    driverFleetUpdate,
    driverFleetCreate,
    driverFleetPageList,
    driverFleetUpdateUseStatus,
    driverFleetDetail,
    selectOrderInfoByDriverAndCar,
    selectDriverFleetLog
}