<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-dialog title=""
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="isEnableShow">
            <div class="check-demand-info">
                <i class="el-icon-warning" style="color: #FAAD14"></i>
                确定{{enableTips.title}}车队：{{enableTips.fleetName}}
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取 消</el-button>
                <el-button size="small" type="primary" @click="handleEnableSubmit">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        props: {
            isEnableShow: {
                type: Boolean,
                default: false
            },
            enableTips: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handleCancel(){
                this.$emit('update:handleCancel', false)
            },
            handleEnableSubmit(){
                this.$emit('update:handleEnableSubmit')
            }


        },
        //监听模版变量
        watch: {}

    }
</script>

