<style lang="scss" scoped>
.product-list {
    .product-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .product-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }
}

.product-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }
}
</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.product-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}

.common-list-search .list-search-before {
    .label-pub-w {
        &.el-form-item.el-form-item--small {
            width: auto;
            /*min-width: 320px;*/
        }

        .el-form-item__content {
            width: auto;
        }
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class=" product-list">
                <!--                <com-list-search>-->
                <!--                    <div slot="list-search-before">-->
                <div style="background-color: #fff;padding: 0 10px">
                    <el-form :inline="true" :model="form" size="small">
                        <el-form-item label="车辆基本信息" label-width="102px" class="label-pub-w">
                            <el-radio-group v-model="form.carAuditStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="1">初审中{{ carFirstCount }}</el-radio-button>
                                <el-radio-button label="4">复审中{{ carSecondCount }}</el-radio-button>
                                <el-radio-button label="2">已通过</el-radio-button>
                                <el-radio-button label="3">未通过</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="备案状态" label-width="82px" class="label-pub-w">
                            <el-radio-group v-model="form.carFlagStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="0">未备案</el-radio-button>
                                <el-radio-button label="1">已备案</el-radio-button>
                                <el-radio-button label="2">备案失败</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="车辆网约信息" label-width="102px" class="label-pub-w">
                            <el-radio-group v-model="form.carOperationAuditStatus ">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="0">未提交</el-radio-button>
                                <el-radio-button label="1">初审中{{ carNetFirstCount }}</el-radio-button>
                                <el-radio-button label="4">复审中{{ carNetSecondCount }}</el-radio-button>
                                <el-radio-button label="2">已通过</el-radio-button>
                                <el-radio-button label="3">未通过</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="司机是否带车" label-width="100px" class="label-pub-w">
                            <el-radio-group v-model="form.personalCar">
                                <el-radio-button :label="null">全部</el-radio-button>
                                <el-radio-button :label="1">是</el-radio-button>
                                <el-radio-button :label="0">否</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label>
                            <brand-model v-model="form.carBrandModel"></brand-model>
                        </el-form-item>
                        <el-form-item label>
                            <seat-model v-model="form.carType" placeholder="车型" is-all></seat-model>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="form.transArea" filterable placeholder="填写城市">
                                <el-option
                                    v-for="city in serverCityList"
                                    :key="city.shortCode"
                                    :label="city.name"
                                    :value="city.name"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-autocomplete
                                v-model="form.carNumberPlate"
                                :fetch-suggestions="querySearcPlate"
                                placeholder="车牌号"
                                :trigger-on-focus="false"
                                @select="handleSelectPlate"
                            >
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <span>{{ item.carNumberPlate }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item style="width: 250px" v-if="userInfo.userType!=1">
                            <el-autocomplete
                                v-model="supplierName"
                                style="width: 250px"
                                :fetch-suggestions="querySearchSupplier"
                                placeholder="供应商"
                                :trigger-on-focus="false"
                                clearable
                                @select="handleSelect"
                            >
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <span>{{ item.supplierName }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.driverName" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.driverMobile" placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <fleet-name-search-input
                                ref="fleetNameSearch"
                                @update:getSelectFleetName="getSelectFleetName"
                            ></fleet-name-search-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="form.carStatus" placeholder="是否可用">
                                <el-option label="不可用" :value="0"></el-option>
                                <el-option label="可用" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="width: 240px">
                            <el-date-picker size="small" style="width: 240px" v-model="form.createOrderTime"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="注册起始日期"
                                            end-placeholder="注册截止日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item style="float: right">
                            <el-button @click="resetClick()">重置</el-button>
                            <el-button type="primary" @click="searchClick()">搜索</el-button>
                            <el-button @click="showSelection=true" v-if="!showSelection">台账批量打印</el-button>
                            <el-button type="warning" v-if="showSelection" @click="handleBatchPrint">确定批量打印
                            </el-button>
                            <el-button plain type="warning" v-if="showSelection" @click="showSelection=false">
                                取消批量打印
                            </el-button>
                            <el-button @click="handlePrintAll()" v-if="!showSelection">打印全部</el-button>
                            <el-button type="success" v-if="showApplyExport" :loading="applyExportLoading"
                                       @click="handleApplyExport">申请导出
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <!--                    </div>-->
                    <!--                    <div slot="list-search-after" class="list-search-after_sec">-->
                    <!--                        <el-button @click="resetClick()">重置</el-button>-->
                    <!--                        <el-button type="primary" @click="searchClick()">搜索</el-button>-->
                    <!--                        <el-button @click="showSelection=true" v-if="!showSelection">台账批量打印</el-button>-->
                    <!--                        <el-button type="warning" v-if="showSelection" @click="handleBatchPrint">确定批量打印-->
                    <!--                        </el-button>-->
                    <!--                        <el-button plain type="warning" v-if="showSelection" @click="showSelection=false">-->
                    <!--                            取消批量打印-->
                    <!--                        </el-button>-->
                    <!--                        <el-button @click="handlePrintAll()" v-if="!showSelection">打印全部</el-button>-->
                    <!--                    </div>-->
                    <!--                </com-list-search>-->
                </div>
                <div class="product-list-table el-table_border_none">
                    <el-table
                        type="index"
                        class="table-info"
                        border
                        :data="driverList"
                        v-loading="listLoading"
                        @selection-change="handleSelectionChange"
                        style="width: 100%"
                    >
                        <el-table-column v-if="showSelection" type="selection" width="55"></el-table-column>
                        <el-table-column prop="carNumberPlate" label="车牌号" width="100"></el-table-column>
                        <el-table-column prop="carOwner" label="车辆所有人" width="100"></el-table-column>
                        <el-table-column label="品牌型号" min-width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.carBrand }}&nbsp;{{ scope.row.carModel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="车型" width="100">
                            <template slot-scope="scope">
                                <span>{{ scope.row.carType ? (formatCarModel(scope.row.carType).name) : '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="城市" width="100" prop="transArea"></el-table-column>
                        <el-table-column prop="carColor" label="颜色" width="60"></el-table-column>
                        <el-table-column prop="supplierName" label="车辆所属供应商" min-width="120"></el-table-column>
                        <el-table-column label="绑定司机" min-width="80">
                            <template slot-scope="scope">
                                <p v-for="item in scope.row.userDriverInfoDtos"
                                   :key="item.id">
                                    <span>{{ item.driverName }}&nbsp;{{ item.driverMobile }}</span></p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fleetName" label="车队" width="120"></el-table-column>
                        <el-table-column label="是否司机带车" width="80">
                            <template slot-scope="scope">
                                <p>
                                    {{ scope.row.personalCar ? '是' : '否' }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="车辆基本信息" width="80">
                            <template slot-scope="scope">
                                <p>
                                    <span
                                        :style="{color:scope.row.carAuditStatus==3?'#FF3B30':''}">{{
                                            formatStatus(scope.row.carAuditStatus).label
                                        }}</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="车辆网约信息" width="110">
                            <template slot-scope="scope">
                                <!--scope.row.-->
                                <p>
                                    <span
                                        :style="{color:scope.row.carOperationAuditStatus==3?'#FF3B30':''}">{{
                                            formatStatus(scope.row.carOperationAuditStatus).label
                                        }}</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="备案状态" width="80" align="center">
                            <template slot-scope="scope">
                                <p>
                                    <span v-if="scope.row.carFlagStatus==0">未备案</span>
                                    <span v-if="scope.row.carFlagStatus==1">已备案</span>
                                    <span style="color:#FF3B30" v-if="scope.row.carFlagStatus==2">备案失败</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否可用" width="80" align="center">
                            <template slot-scope="scope">
                                <p>
                                    <i class="icon"
                                       :class='scope.row.carStatus === 0 ? "icon-is-cancel" : "icon-order-completed"'
                                       :style="{color:scope.row.carStatus === 0?'#FF3B30':'#2FCF1B'}"
                                    ></i>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="130">
                            <template slot-scope="scope">
                                <div class="operation-btn clear">
                                    <el-button type="text" @click="goEdit(scope.row)">查看</el-button>
                                    <el-button type="text" @click="showLog(scope.row)">日志</el-button>
                                    <el-button type="text"
                                               v-if="scope.row.carAuditStatus===2"
                                               :style="{color:scope.row.carStatus === 1?'#FF3B30':'#2FCF1B'}"
                                               @click="handleEnablingVehicles(scope.row)">
                                        {{ scope.row.carStatus === 0 ? '启用' : '停用' }}
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="pageChange"
                        :current-page.sync="pageData.currentPage"
                        :page-size="pageData.pageSize"
                        layout="total, prev, pager, next"
                        :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="dialogTableVisible">
            <el-table :data="logList">
                <el-table-column label="操作人" width="250">
                    <template slot-scope="scope">
                        {{
                            `${scope.row.operateName}${scope.row.operateMobile ? ('(' + scope.row.operateMobile + ')') : ''}`
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="操作时间" width="200">
                    <template slot-scope="scope">{{ scope.row.operateTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</template>
                </el-table-column>
                <el-table-column property="operateContent" label="内容"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCityData} from "@/urls/index";
// import comListSearch from "../../../components/comListSearch/index.vue";
import SeatModel from "@/components/seatModel/index";
import {seatModelList, getSeatModel} from "@/data/index";
import {getStatusInfo} from "../service"
import BrandModel from '@/components/brandModel/index'

import fleetNameSearchInput from '../components/fleetNameSearchInput/index'
import {applyExportCarListApi} from "@/www/urls/driverManage";
import dayjs from 'dayjs'
export default {
    //定义模版数据
    name: "vehicleList",
    data() {
        return {
            form: {
                carAuditStatus: "", // 司机信息
                carOperationAuditStatus: "", // 绑定车辆
                carFlagStatus: "", // 是否可用
                carType: 0,
                personalCar: null,//是否自己带车
                carStatus: undefined
            },
            driverList: [],
            listLoading: false,
            dialogUpperLower: false,
            seatModelList: seatModelList,

            productShapeOp: [],
            supplierList: [], // 供应商列表
            pageData: {
                // 列表分页
                pageSize: 10, // 每页显示条目个数
                totalPage: 1, // 总条目个数
                currentPage: 1 // 当前页数
            },
            serverCityList: [],
            supplierName: "",
            carNetFirstCount: 0,
            carNetSecondCount: 0,
            carFirstCount: 0,
            carSecondCount: 0,
            dialogTableVisible: false,
            logList: [],
            showSelection: false,
            applyExportLoading: false
        };
    },
    components: {
        // comListSearch,
        SeatModel,
        BrandModel,
        fleetNameSearchInput
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        showApplyExport() {
            return ['总部管理员', '总部运力管理员', "总部超级管理员"].includes(this.userInfo.roleName)
        },
    },
    //主件被加载完成
    mounted() {
        this.searchClick();
        this.getServerCity();
        this.getStatusInfo();
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            const ret = await getCityData(this, "2");
            if (ret) this.serverCityList = ret;
        },
        createFilter(itemString) {
            return restaurant => {
                return restaurant.supplierName.indexOf(itemString) !== -1;
            };
        },
        async querySearchSupplier(supplierString, cb) {
            // 供应商列表
            let form = {
                companyName: supplierString
            };
            let supplierLists = [];
            let res = await this.http(
                "/galaxySupplier/supplierInfoPageList",
                form,
                "POST"
            );
            if (res.success) {
                supplierLists = res.data.data;
                let supplierRes = supplierString
                    ? supplierLists.filter(this.createFilter(supplierString))
                    : supplierLists;
                // 调用 callback 返回建议列表的数据
                cb(supplierRes);
            }
        },
        createFilterPlate(itemString) {
            return restaurant => {
                return restaurant.carNumberPlate.indexOf(itemString) === 0;
            };
        },
        async querySearcPlate(query, cb) {
            // 供应商列表
            let plateList = [];
            let res = await this.http(
                `/galaxyDriverApproveApi/doGalaxyGetCarInfoByNumberPlate/${query}`
            );
            if (res.success) {
                plateList = res.data;
                let plateLists = query
                    ? plateList.filter(this.createFilterPlate(query))
                    : plateList;
                // 调用 callback 返回建议列表的数据
                cb(plateLists);
            }
        },
        resetClick() {
            // 重置
            this.form = {
                carAuditStatus: "", // 司机信息
                carOperationAuditStatus: "", // 绑定车辆
                carFlagStatus: "", // 是否可用
                carType: 0,
                personalCar: null
            };
            this.supplierName=''
            this.$refs.fleetNameSearch.clear()
            this.defaultFleetName = ""
            this.searchClick();
        },
        pageChange(val) {
            this.pageData.currentPage = val;
            this.searchClick();
        },

        getSelectFleetName(info) {
            this.form.fleetId = info.id;
            this.driverFleetName = info.fleetName
        },
        getSearchForm() {
            let form = JSON.parse(JSON.stringify(this.form));
            form.pageIndex = this.pageData.currentPage;
            form.pageSize = this.pageData.pageSize;
            delete form.carBrandModel;
            if (this.form.carBrandModel && this.form.carBrandModel.length > 0) {
                form.carBrand = this.form.carBrandModel[0];
                form.carModel = this.form.carBrandModel[1];
            }

            if (form.createOrderTime) {
                form.createTimeStart = +new Date(form.createOrderTime[0]);
                form.createTimeEnd = dayjs(form.createOrderTime[1]).endOf('day').valueOf();
                delete form.createOrderTime;
            }
            if (form.carType === 0) delete form.carType;
            return form;
        },
        async searchClick() {
            try {
                this.loadingShow()
                const form = this.getSearchForm();
                let res = await this.http(
                    "/galaxyDriverApproveApi/doCarInfoApprovalList",
                    form,
                    "POST"
                );
                console.log(res);
                if (res.success) {
                    this.driverList = res.data.data;
                    this.pageData.totalPage = res.data.total;
                }
            } finally {
                this.loadingHide()
            }
        },
        goEdit(data) {
            this.$router.push(`/vehicleDetail/${data.id}`);
        },
        handleSelect(item) {
            console.log(item);
            this.supplierName = item.supplierName;
            this.form.supplierId = item.id;
        },
        handleSelectPlate(item) {
            this.form.carNumberPlate = item.carNumberPlate;
        },
        async enable(index, bool) {
            let form = {
                driverStatus: bool ? 1 : 0,
                loginUserId: this.driverList[index].loginUserId
            };
            let res = await this.http(
                "/galaxyDriverApproveApi/doGalaxyDriverStatusChange",
                form,
                "POST"
            );
            if (res.success) {
                this.$message.success("操作成功");
                let data = JSON.parse(JSON.stringify(this.driverList[index]));
                data.isValid = bool ? 1 : 0;
                this.$set(this.driverList, index, data);
            }
        },
        async getStatusInfo() {
            let res = await this.http(
                "/galaxyDriverApproveApi/doCarAuditStatusCount",
                {},
                "POST"
            );
            console.log(res);
            if (res.success) {
                this.carFirstCount = res.data.carFirstAuditStatusCount;
                this.carSecondCount = res.data.carSecondAuditStatusCount;
                this.carNetFirstCount = res.data.carNetCarFirstAuditStatusCount;
                this.carNetSecondCount = res.data.carNetCarSecondAuditStatusCount;
            }
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        formatStatus(status) {
            let info = getStatusInfo(status);
            return info;
        },
        async showLog(item) {
            this.logList = [];
            let res = await this.http(`/galaxyDriverApproveApi/doCarInfoLogList/${item.carNumberPlate}`);
            this.dialogTableVisible = true;
            if (res.success) {
                if (res.data.length > 0) {
                    this.logList = res.data.sort((a, b) => {
                        return b.createTime - a.createTime
                    });
                }
            }
        },
        //启/停用车辆
        async handleEnablingVehicles({carStatus, id}) {
            try {
                await this.$confirm(`是否${carStatus === 0 ? '启用' : '停用'}当前车辆?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                this.loadingShow();
                const ret = await this.http('/galaxyDriverApproveApi/carUpdateCarStatus', {
                    id,
                    carStatus: carStatus === 0 ? 1 : 0
                }, 'POST');
                if (ret.success && ret.data) {
                    await this.searchClick();
                    this.loadingHide();
                    this.$message.success('车辆启用成功！');
                } else {
                    this.loadingHide();
                    this.$message.error(ret.errors[0].message)
                }

            } catch (e) {
                console.log(e);
            }
        },
        async getListData(pageIndex) {
            const form = this.getSearchForm();
            form.pageIndex = pageIndex;
            form.pageSize = 50;
            let res = await this.http("/galaxyDriverApproveApi/doCarInfoApprovalList", form, "POST");
            if (res.success) {
                return res.data;
            }
        },
        //打印全部数据
        async handlePrintAll(list = [], pageIndex = 1) {
            let total = 0;
            try {
                this.loadingShow();
                const ret = await this.getListData(pageIndex)
                if (ret && ret.data) {
                    list.push(...ret.data)
                    total = ret.total
                }
            } finally {
                if (pageIndex * 50 < total) {
                    this.handlePrintAll(list, pageIndex + 1);
                } else {
                    this.loadingHide();
                    if (!list.length) {
                        this.$alert('当前搜索条件下无数据', '提示', {
                            type: "error"
                        });
                    } else {
                        this.$router.push(`/infoPrint/1?ids=${list.map(({id}) => id).join(',')}`)
                    }
                }
            }
        },
        //批量打印
        handleBatchPrint() {
            if (!this.selection || !this.selection.length)
                return this.$alert('请先选择打印项', '提示', {
                    type: "error"
                });
            this.showSelection = false;
            this.$router.push(`/infoPrint/1?ids=${this.selection.map(({id}) => id).join(',')}`)
        },
        handleSelectionChange(selection) {
            this.selection = selection;
        },
        //申请导出
        async handleApplyExport() {
            try {
                this.applyExportLoading = true;
                await this.$confirm('确定申请导出？', '提示');
                console.log(this.getSearchForm())
                const ret = await applyExportCarListApi({
                    ...this.getSearchForm(),
                    supplierName: this.supplierName,
                    driverFleetName: this.driverFleetName
                })
                if (ret.success) {
                    this.$message.success('导出申请提交成功~')
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.applyExportLoading = false;
            }
        }
    },
    //监听模版变量
    watch: {}
};
</script>

