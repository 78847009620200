<style lang="scss" scoped>
.anti-epidemic-form {
    &::v-deep {
        .common-list-search {
            .el-select {
                width: 100%;
            }

            .list-search-before {
                width: calc(100% - 160px);

                .el-form-item.el-form-item--small {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }

            .list-search-after {
                width: 160px;

                .el-button + .el-button {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>


<template>
    <div class="anti-epidemic-form">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item style="width: 160px">
                        <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="form.createTime"
                            style="width: 100%;"
                            value-format="yyyy/MM/dd"
                            :clearable="false">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="width: 220px">
                        <el-select
                            v-model="form.cityName"
                            filterable
                            placeholder="选择营运城市">
                            <el-option
                                v-for="item in cityList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width: 280px">
                        <el-select
                            v-model="form.supplierId"
                            filterable
                            placeholder="请选择供应商">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width: auto;">
                        <el-radio-group v-model="form.isAbnormal">
                            <el-radio-button label="">全部</el-radio-button>
                            <el-radio-button v-for="item in isAbnormalItem" :key="item.value" :label="item.value">
                                登记{{ item.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item style="width: 160px">
                        <el-input v-model="form.driverName" placeholder="司机姓名"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="list-search-after" class="list-search-after_sec">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="search">搜索</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import ComListSearch from '@/www/components/comListSearch'

import {getCityData, getSupplierList} from "@/urls";
import {isAbnormalItem} from '../../data/index'
import {combinationForm} from '@/common/js/common'
import {formatDayjsDate} from '@/common/js/dayjsDate/index'

const initForm = {
    isAbnormal: '',
}
export default {
    name: '',
    data() {
        return {
            isAbnormalItem,
            form: {
                ...initForm
            },
            cityList: [],
            supplierList: []
        }
    },
    components: {
        ComListSearch
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        this.getCityList()
        this.getOrgList()
    },
    methods: {
        // 获取城市
        async getCityList() {
            const res = await getCityData(this, '2');
            if (res) this.cityList = res;
        },
        // 机构列表
        async getOrgList() {
            const res = await getSupplierList();
            if (res) this.supplierList = res
        },
        reset() {
            this.form = {...initForm}
            let form = combinationForm(this.form)
            this.$emit('search', form)
        },
        search() {
            let {
                createTime,
                cityName,
                supplierId,
                isAbnormal,
                driverName,
            } = this.form
            let form = {
                cityName,
                supplierId,
                isAbnormal,
                driverName,
            }
            if(createTime) form.createTime = formatDayjsDate(createTime, 'YYYY-MM-DD'),
            form = combinationForm(form)
            this.$emit('search', form)
        },
    },
    watch: {},
}
</script>
