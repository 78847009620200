<style lang="scss" scoped>
    .mod-driver-confirm {
        .del-add-cont {
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
            padding-left: 35px;
            position: relative;
        }

        .del-add-head {
            font-weight: 600;
            margin-bottom: 12px;
            .el-icon-warning {
                position: absolute;
                left: 0;
                top: -1px;
                font-size: 22px;
                color: #FAAD14
            }
        }

        .del-add-list {
            display: flex;
            line-height: 22px;
            font-size: 14px;
            margin-bottom: 20px;

            p {
                width: 50px;
            }

            .color-red {
                color: #FF3B30;
            }

            .color-black {
                color: #000000;
            }

        }
    }

</style>
<template>

    <div>
        <el-dialog title=""
                   width="420px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="show">
            <div class="del-add-cont"
                 v-if="(delInfo.driverList && delInfo.driverList.length > 0) || (delInfo.carList&& delInfo.carList.length > 0) || (addInfo.driverList&&addInfo.driverList.length>0) || (addInfo.carList&&addInfo.carList.length>0)">
                <div class="del-add-head">
                    <i class="el-icon-warning"></i>
                    请确认以下信息：
                </div>
                <div class="del-add-list" v-if="delInfo.driverList.length > 0 || delInfo.carList.length > 0">
                    <p>删除：</p>
                    <ul>
                        <li v-for="item in delInfo.driverList" :key="item.id" class="color-red">
                            <span class="color-black">司机</span>{{item.driverName}} ({{item.driverMobile}})
                        </li>
                        <li v-for="item in delInfo.carList" :key="item.id" class="color-red">
                            <span class="color-black">车辆</span>{{item.carNumberPlate}}，
                            {{item.carBrand}}
                            {{item.carModel}}，
                            {{item.carType | seatModelDesc}}，
                            {{item.carColor}}
                        </li>
                    </ul>
                </div>
                <div class="del-add-list" v-if="addInfo.driverList.length>0 || addInfo.carList.length>0">
                    <p>新增：</p>
                    <ul>
                        <li v-for="item in addInfo.driverList" :key="item.id" class="color-red">
                            <span class="color-black">司机</span>{{item.driverName}} ({{item.driverMobile}})
                        </li>
                        <li v-for="item in addInfo.carList" :key="item.id" class="color-red">
                            <span class="color-black">车辆</span>{{item.carNumberPlate}}，
                            {{item.carBrand}}
                            {{item.carModel}}，
                            {{item.carType | seatModelDesc}}，
                            {{item.carColor}}
                        </li>
                    </ul>
                </div>

            </div>
            <div v-else>未修改司机或车辆</div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取 消</el-button>
                <el-button size="small" type="primary" @click="handleSubmit()">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import {seatModelList} from "@/data/index";

    export default {
        //定义模版数据
        data() {
            return {
                seatModelList
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }, //  是否显示
            delInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },  // 删除列表
            addInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            }  // 增加列表
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {},
        //定义事件方法
        methods: {
            handleCancel() {
                this.$emit('update:getCancelBtn', false)
            },  // 取消
            handleSubmit() {
                this.$emit('update:getSubmitBtn', false)
            },  // 提交
        },
        //监听模版变量
        watch: {}

    }
</script>

