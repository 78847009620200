<style lang="scss" scoped>
</style>
<template>
    <div>
        <el-autocomplete
                :value="value"
                placeholder="车牌号"
                :trigger-on-focus="false"
                :fetch-suggestions="querySearch"
                @input="input"
                v-model="form.carNumberPlate"
                @select="handleSelect">
            <template slot-scope="{item}">
                <div class="list-pop-bot">
                    <span>{{item.carNumberPlate}}</span>
                </div>
            </template>
        </el-autocomplete>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        data() {
            return {
                form: {
                    carNumberPlate: null
                }
            }
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        methods: {
            createFilterPlate(itemString) {
                return restaurant => {
                    return restaurant.carNumberPlate.indexOf(itemString) === 0;
                };
            },
            async querySearch(query, cb) {
                console.log(query);
                let plateList = [];
                let res = await this.http(
                    `/galaxyDriverApproveApi/doGalaxyGetCarInfoByNumberPlate/${query}`
                );
                if (res.success) {
                    plateList = res.data;
                    let plateLists = query
                        ? plateList.filter(this.createFilterPlate(query))
                        : plateList;
                    cb(plateLists);
                }
            },
            handleSelect({carNumberPlate}) {
                this.form.carNumberPlate = carNumberPlate;
                this.$emit('input', carNumberPlate)
            },
            input(val) {
                this.$emit('input', val)
            }
        }
    }
</script>
