<style lang="scss" scoped>
    .is-fleet-log-dialog /deep/ {

        .close-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            .el-icon-close {
                font-size: 26px;
            }
        }
    }
</style>
<template>

    <div>
        <el-dialog title=""
                   width="720px"
                   :close-on-click-modal="false"
                   :show-close="false"
                   custom-class="el-dialog-middle-center"
                   class="is-fleet-log-dialog"
                   :visible.sync="isFleetLogShow">
            <div class="close-btn" @click="handleLogClose">
                <i class="el-icon-close" style="color: #999"></i>
            </div>
            <el-table :data="fleetLogList" v-loading="fleetLogLoading">
                <el-table-column label="操作人" width="250">
                    <template slot-scope="scope">
                        {{scope.row.operateName}}
                    </template>
                </el-table-column>
                <el-table-column label="操作时间" width="200">
                    <template slot-scope="scope">{{ scope.row.operateTime | dateCus('yyyy-MM-dd hh:mm:ss')}}</template>
                </el-table-column>
                <el-table-column property="operateContent" label="内容"></el-table-column>
            </el-table>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        props: {
            isFleetLogShow: {
                type: Boolean,
                default: false
            },
            fleetLogList: {
                type: Array,
                default: function () {
                    return []
                }
            },
            fleetLogLoading: {
                type: Boolean,
                default: false
            }

        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handleLogClose() {
                this.$emit('update:handleLogClose', false)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

