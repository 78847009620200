import http from "@/common/js/http";


/**
 * 查询车辆
 * @param data
 * @returns {Promise<void>}
 */
export const checkCarApi = function (data) {
    return http('/galaxyDriverApproveApi/checkCar', data, 'POST')
}

/**
 * 查询司机
 * @param data
 * @returns {Promise<void>}
 */
export const checkDriverApi = function (data) {
    return http('/galaxyDriverApproveApi/checkDriver', data, 'POST')
}
