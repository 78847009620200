<style lang="scss" scoped>
.container {
    width: 300px;
}
</style>
<template>

    <div class="container" v-loading="loading">
        <el-image lazy
                  style="width: 286px;height: 186px;"
                  :src="url||images[id]"
                  :preview-src-list="[url||images[id]]">
        </el-image>
        <file-upload :id="id"
                     :showFileList="false"
                     :customStyle="true"
                     @onStart="loading=true"
                     @onCompleted="handleImageUpload">
            <div style="text-align: center;width: 286px;">
                <el-button>
                    <i class="icon icon-upload-arrow"></i>
                    上传图片
                </el-button>
            </div>
        </file-upload>
    </div>

</template>

<script type="text/ecmascript-6">
import FileUpload from '@/components/fileUpload'

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            images: {
                carLicenseFacePhoto: require('../../../assets/images/addVehicle/carLicenseFacePhoto.png'),
                carLicenseBackPhoto: require('../../../assets/images/addVehicle/carLicenseBackPhoto.png'),
                carPhoto: require('../../../assets/images/addVehicle/carPhoto.png'),
                transportCertificatePhoto: require('../../../assets/images/addVehicle/transportCertificatePhoto.png'),
            }
        }
    },
    props: {
        url: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        }
    },
    //计算属性
    computed: {},
    components: {
        FileUpload
    },
    //主件被加载完成
    mounted() {

    },
    //定义事件方法
    methods: {
        handleImageUpload(file) {
            this.$emit('onFileUpload', file);
            this.loading = false;
        }
    },
    //监听模版变量
    watch: {}

}
</script>
