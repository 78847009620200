<style lang="scss" scoped>
.driver-detail {
    .driver-section-cont {
        .box-card {
            margin-top: 10px;
            padding-bottom: 5px;
        }

        .box-title {
            height: 48px;
            border-bottom: 1px solid #f8f8f8;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 48px;
            padding-left: 20px;
        }

        .driver-box-card {
            padding: 10px;

            p {
                margin-bottom: 10px;
            }

            .verify-status {
                float: left;
                margin-left: 10px;
                height: 32px;
                width: 179px;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                border-radius: 4px;
                text-align: center;
            }

            .verify-default {
                background: #fff6e7;
                color: rgba(219, 145, 23, 1);
                border: 1px solid #db9117;
            }

            .verify-default1 {
                background: rgba(228, 255, 220, 1);
                color: rgba(32, 146, 139, 1);
                border: 1px solid rgba(32, 146, 139, 1);
            }

            .verify-success {
                background: rgba(240, 248, 255, 1);
                color: rgba(24, 144, 255, 1);
                border: 1px solid rgba(25, 137, 250, 1);
            }

            .verify-refuse {
                background: rgba(255, 239, 238, 1);
                border: 1px solid rgba(255, 59, 48, 1);
                color: rgba(255, 59, 48, 1);
            }

            .verify-btn {
                width: 121px;
                height: 32px;
                margin-right: 20px;
            }
        }

        .label-title {
            border-left: 2px solid #319bf7;
            padding-left: 10px;
            font-weight: 700;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .table {
        margin-left: 10px;

        tr td {
            width: 190px;
            height: 34px;
            border: 1px solid rgba(220, 223, 230, 1);
            font-weight: 500;
            line-height: 20px;
            font-size: 14px;
            padding-left: 10px;
        }

        .table-title {
            background: rgba(240, 242, 245, 1);
            color: rgba(144, 147, 153, 1);
        }

        .insur td {
            height: 51px;
            padding: 0 5px;

            div {
                margin-bottom: 0;
            }
        }
    }

    .excel-file-btns {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
</style>
<style lang="scss">
.driver-section-cont {
    .el-input.is-disabled {
        .el-input__inner {
            color: #777;
            background-color: #f6f6f6;
        }
    }

    .el-card__body {
        padding: 0;
    }

    table {
        .el-input__inner {
            border: 0;
        }
    }

    /*.preview_img {*/
    /*line-height: initial;*/
    /*}*/
    .preview_img {
        line-height: initial;
    }

}
</style>

<template>
    <div class="driver-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="driver-section-cont">
                <el-row>
                    <el-form label-position="right" label-width="120px" size="small" :model="form" :rules="ruleForm"
                             ref="form">
                        <el-card class="box-card">
                            <p class="box-title" style="margin-bottom: 10px">所属供应商</p>
                            <el-form-item label="所属供应商：" prop="carSupplierId">
                                <el-select v-model="form.carSupplierId"
                                           style="width: 314px"
                                           filterable
                                           :disabled="disabledSupplier"
                                           placeholder="选择所属供应商">
                                    <el-option
                                        v-for="item in supplierList"
                                        :key="item.id"
                                        :label="item.supplierName"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <p class="box-title">车辆基本信息</p>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">行驶证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="车辆营运城市：" prop="transArea">
                                        <el-select v-model="form.transArea" filterable placeholder="填写城市"
                                                   :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="city in serverCityList"
                                                :key="city.shortCode"
                                                :label="city.name"
                                                :value="city.name"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="车牌号码：" prop="carNumberPlate">
                                        <div style="display: flex;">
                                            <el-select v-model="form.provincial" :disabled="!isEdit"
                                                       style="width: 80px">
                                                <el-option :value="vm.value" v-for="vm in plateCity"
                                                           :key="vm.value"></el-option>
                                            </el-select>
                                            <el-input v-model="form.carNumberPlate" :maxLength="7"
                                                      :disabled="!isEdit"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="车牌颜色：" prop="plateColor">
                                        <el-select v-model="form.plateColor" :disabled="!isEdit" style="width: 100%">
                                            <el-option v-for="(vm,idx) in plateColors" :value="vm"
                                                       :key="idx"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="证件车辆类型：" prop="licenseCarType">
                                        <el-input v-model="form.licenseCarType" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆所有人：" prop="carOwner">
                                        <el-input v-model="form.carOwner" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="使用性质：" prop="useCharacter">
                                        <el-input v-model="form.useCharacter" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <!--                                    <el-form-item label="证件品牌型号：" prop="licenseCarModel">-->
                                    <el-form-item label="证件品牌型号：">
                                        <el-input v-model="form.licenseCarModel" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆识别代码：" prop="vin">
                                        <el-input v-model="form.vin" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="发动机号码：" prop="engineId">
                                        <el-input v-model="form.engineId" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆注册日期：" prop="carRegisterDate">
                                        <el-date-picker type="date" v-model="form.carRegisterDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="发证日期：" prop="issueDate">
                                        <el-date-picker type="date" v-model="form.issueDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="核定载客人数：" prop="seats">
                                        <el-input v-model="form.seats" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="下次年检日期：" prop="nextFixDate">
                                        <el-date-picker type="date" v-model="form.nextFixDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="平台品牌型号：" prop="carBrandModel">
                                        <brand-model v-model="form.carBrandModel" :disabled="!isEdit"
                                                     @selectBrand="selectBrand"></brand-model>
                                    </el-form-item>
                                    <el-form-item label="平台车辆类型：" prop="carType">
                                        <seat-model v-model="form.carType"></seat-model>
                                    </el-form-item>
                                    <el-form-item label="车身颜色：" prop="carColor">
                                        <el-select v-model="form.carColor" :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="car in vehicleColors"
                                                :key="car"
                                                :label="car"
                                                :value="car"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="燃油类型：" prop="fuelType">
                                        <el-select v-model="form.fuelType" :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="item in fuelTypes"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="发动机排量：" prop="engineDisplace">
                                        <el-select v-model="form.engineDisplace" :disabled="!isEdit"
                                                   style="width: 100%">
                                            <el-option
                                                v-for="item in engineDisplaces"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="车辆轴距：" prop="wheelbase">
                                        <el-input v-model="form.wheelbase"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="行驶证正面：" prop="carLicenseFacePhoto"
                                                  v-loading="imageLoading===1">
                                        <div class="preview_img">
                                            <image-panel id="carLicenseFacePhoto"
                                                         :url="form.carLicenseFacePhoto"
                                                         @onFileUpload="handleImageUpload"></image-panel>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="行驶证反面：" prop="carLicenseBackPhoto"
                                                  v-loading="imageLoading===2">
                                        <div class="preview_img">
                                            <image-panel id="carLicenseBackPhoto"
                                                         :url="form.carLicenseBackPhoto"
                                                         @onFileUpload="handleImageUpload"></image-panel>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="车辆照片：" prop="carPhoto">
                                        <div class="preview_img">
                                            <image-panel id="carPhoto"
                                                         :url="form.carPhoto"
                                                         @onFileUpload="handleImageUpload"></image-panel>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">其他信息</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="车辆检修状态：" prop="fixState">
                                        <el-select v-model="form.fixState" :disabled="!isEdit" style="width: 100%">
                                            <el-option label="未检修" :value="0"></el-option>
                                            <el-option label="已检修" :value="1"></el-option>
                                            <el-option label="未知" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="车辆年检状态：" prop="checkState">
                                        <el-select v-model="form.checkState" :disabled="!isEdit" style="width: 100%">
                                            <el-option label="未年审" :value="0"></el-option>
                                            <el-option label="年审合格" :value="1"></el-option>
                                            <el-option label="年审不合格" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="行驶总里程：" prop="total">
                                        <el-input v-model="form.total" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆服务类型：" prop="carCommercialType">
                                        <el-select v-model="form.carCommercialType" :disabled="!isEdit"
                                                   style="width: 100%">
                                            <el-option label="网络预约出租汽车" :value="1"></el-option>
                                            <el-option label="巡游出租汽车" :value="2"></el-option>
                                            <el-option label="私人小客车合成" :value="3"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="9">
                                    <el-form-item label="发票打印设备序列号：" label-width="180px" prop="feePrintId">
                                        <el-input v-model="form.feePrintId" :maxLength="11"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置品牌：" label-width="180px" prop="gpsBrand">
                                        <el-input v-model="form.gpsBrand" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置型号：" label-width="180px" prop="gpsModel">
                                        <el-input v-model="form.gpsModel" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置IMEI：" label-width="180px" prop="gpsImei">
                                        <el-input v-model="form.gpsImei" :maxLength="11" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位设备安装日期：" label-width="180px"
                                                  prop="gpsInstalDate">
                                        <el-date-picker type="date" v-model="form.gpsInstalDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                    </el-form>
                    <el-form label-position="right"
                             label-width="120px"
                             v-if="isSubmitNetCarInfo"
                             size="small" :model="netForm" :rules="ruleForm"
                             ref="netRule">
                        <el-card class="box-card">
                            <p class="box-title">车辆网约车信息</p>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">运输证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="发证机构：" prop="transAgency">
                                        <el-input v-model="netForm.transAgency" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="运输证字号：" prop="certificate">
                                        <el-input v-model="netForm.certificate " :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="有效期限：">
                                        <el-col :span="11">
                                            <el-form-item prop="transDateStart">
                                                <el-date-picker type="date" v-model="netForm.transDateStart"
                                                                :disabled="!isEdit" style="width: 100%;"
                                                                value-format="yyyy/MM/dd"></el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                        <el-col class="line" :span="2" style="text-align: center">-</el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="transDateStop">
                                                <el-date-picker type="date" v-model="netForm.transDateStop"
                                                                :disabled="!isEdit" style="width: 100%;"
                                                                value-format="yyyy/MM/dd"></el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="运输证正面：" prop="transportCertificatePhoto">
                                        <div class="preview_img">
                                            <image-panel id="transportCertificatePhoto"
                                                         :url="netForm.transportCertificatePhoto"
                                                         @onFileUpload="handleImageUpload"></image-panel>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">保险信息</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td style="width:80px;">保险类型</td>
                                        <td>保险公司名称</td>
                                        <td style="width:220px;">保单号</td>
                                        <td>保额(元)</td>
                                        <td>有效期限</td>
                                        <td>保单照片</td>
                                    </tr>
                                    <tr v-for="(item,index) in netForm.userCarInsuranceDto" :key="item.insurType"
                                        class="insur">
                                        <td style="width:80px;">{{ item.insurType }}</td>
                                        <td>
                                            <el-form-item label-width="0"
                                                          :prop="'userCarInsuranceDto.' + index + '.insurCompany'"
                                                          :rules="[{ validator: validateChart, trigger: 'blur' }]">
                                                <el-input v-model="item.insurCompany" :disabled="!isEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td style="width:220px;">
                                            <el-form-item label-width="0"
                                                          :prop="'userCarInsuranceDto.' + index + '.insurNum'"
                                                          :rules="[{ validator: validateLettersOrNumber, trigger: 'blur' }]">
                                                <el-input v-model="item.insurNum" :disabled="!isEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td>
                                            <el-form-item label-width="0"
                                                          :prop="'userCarInsuranceDto.' + index + '.insurCount'"
                                                          :rules="[{validator:validateNumberRequire,  trigger: 'blur'}]">
                                                <el-input v-model="item.insurCount" :disabled="!isEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td style="padding-left: 0;width: 340px">
                                            <el-col :span="11">
                                                <el-form-item label-width="0"
                                                              :prop="'userCarInsuranceDto.' + index + '.insurEff'"
                                                              :rules="[{required: true, message: '请选择日期', trigger: 'change'}]">
                                                    <el-date-picker type="date" v-model="item.insurEff"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col class="line" :span="2" style="text-align: center;line-height: 32px">
                                                至
                                            </el-col>
                                            <el-col :span="11">
                                                <el-form-item label-width="0"
                                                              :prop="'userCarInsuranceDto.' + index + '.insurExp'"
                                                              :rules="[{required: true, message: '请选择日期', trigger: 'change'}]">
                                                    <el-date-picker type="date" v-model="item.insurExp"
                                                                    :disabled="!isEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                        </td>
                                        <td>
                                            <div :disabled="!isEdit">
                                                <div class="excel-file-btns">
                                                    <el-image lazy
                                                              v-if="item.insurPhoto"
                                                              style="width: 23px;height: 35px;"
                                                              :src="item.insurPhoto"
                                                              :preview-src-list="[item.insurPhoto]">
                                                    </el-image>
                                                    <div style="position: relative" v-if="item.insurPhoto">
                                                        <el-image
                                                            style="width: 40px;height: 35px;position: absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: 99;"
                                                            :src="require('@/assets/images/_blank.png')"
                                                            :preview-src-list="[item.insurPhoto]">
                                                        </el-image>
                                                        <el-button type="text">查看</el-button>
                                                    </div>
                                                    <el-form-item
                                                        label-width="0"
                                                        :prop="'userCarInsuranceDto.' + index + '.insurPhoto'"
                                                        :rules="[{required: true, message: '请上传保单', trigger: 'change'}]">
                                                        <file-upload :id="'insurPhoto'+index"
                                                                     :showFileList="false"
                                                                     :customStyle="true"
                                                                     @onCompleted="handleImageUpload">
                                                            <el-button type="text">上传保单</el-button>
                                                        </file-upload>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-card>
                    </el-form>
                </el-row>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="text-align: right">
                    <el-row>
                        <div style="line-height: 40px;float: right;margin-right: 20px">
                            <el-checkbox v-if="isEdit"
                                         v-model="isSubmitNetCarInfo">上传车辆网约车信息
                            </el-checkbox>
                            <el-button type="primary"
                                       v-if="isEdit"
                                       @click="handleSubmit"
                                       class="edit-button"
                                       size="small">提交
                            </el-button>
                            <el-button type="primary"
                                       v-if="!isEdit"
                                       @click="isEdit=true"
                                       class="edit-button"
                                       size="small">返回编辑
                            </el-button>
                            <el-button type="primary"
                                       v-if="!isEdit"
                                       @click="handleSave"
                                       class="edit-button"
                                       size="small">确认保存
                            </el-button>
                        </div>
                    </el-row>
                </div>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCityData} from "@/urls/index";
import {getSeatModel} from "@/data/index";
import plateCity from '@/common/js/data/plateCity.json'
import BrandModel from "@/components/brandModel/index";
import SeatModel from "@/components/seatModel/index";
import {getAllSupplier} from '../../../urls/orderUrls'
import {vehicleColors, fuelTypes, engineDisplaces, plateColors} from '@/data/vehicleData/index'
import {
    validateChinese, validateChart, validateNumber,
    validateLettersOrNumber, validatePlate, validateNumberRequire
} from "../service"
import SectionContent from "@/components/sectionContent/index.vue";
import ImagePanel from '../components/ImagePanel.vue'
import FileUpload from '@/components/fileUpload'

let date = null;
let initForm = {
    carSupplierId: null,
    transArea: '上海市',
    provincial: '沪',
    carNumberPlate: null,
    plateColor: plateColors[0],
    licenseCarType: null,
    carOwner: null,
    useCharacter: null,
    licenseCarModel: null,
    vin: null,
    engineId: null,
    carRegisterDate: date,
    issueDate: date,
    seats: 4,
    nextFixDate: date,
    carBrandModel: [],
    carType: 1,
    carColor: vehicleColors[0],
    fuelType: fuelTypes[0],
    engineDisplace: engineDisplaces[0],
    wheelbase: null,
    carLicenseFacePhoto: null,
    carLicenseBackPhoto: null,
    carPhoto: null,
    fixState: 1,
    checkState: 1,
    total: 30000,
    carCommercialType: 1,
    feePrintId: null,
    gpsBrand: '汇星行',
    gpsModel: 'VGC-8',
    gpsImei: '',
    gpsInstalDate: date
}
let initNetForm = {
    transAgency: null,
    certificate: null,
    transDateStart: date,
    transDateStop: date,
    transportCertificatePhoto: null
}
const createCarInsurance = () => ['交强险', '商业险', '意外险'].map((item) => ({
    insurType: item,
    insurCompany: null,
    insurNum: null,
    insurCount: null,
    insurEff: date,
    insurExp: date,
    insurPhoto: null,
    carNumberPlate: null
}))
let userCarInsuranceDto = createCarInsurance();

export default {
    name: 'addVehicle',
    //定义模版数据
    data() {
        return {
            isEdit: true,
            supplierList: [],
            disabledSupplier: false,
            isSubmitNetCarInfo: true,//是否上传网约车信息
            form: {
                ...initForm
            },
            netForm: {
                ...initNetForm,
                userCarInsuranceDto: [...userCarInsuranceDto]
            },
            serverCityList: [],
            brandModelList: [],
            plateCity,
            vehicleColors,
            fuelTypes,
            engineDisplaces,
            plateColors,
            ruleForm: {
                carSupplierId: [
                    {required: true, message: '请先选择供应商', trigger: 'change'}
                ],
                carNumberPlate: [{
                    validator: (rule, value, callback) => validatePlate(rule, this.form.provincial + value, callback),
                    trigger: 'blur'
                }],
                plateColor: [{required: true, message: '请选择颜色', trigger: 'change'}],
                carLicenseFacePhoto: [{required: true, message: '请上传行驶证正面', trigger: 'change'}],
                carLicenseBackPhoto: [{required: true, message: '请上传行驶证反面', trigger: 'change'}],
                carPhoto: [{required: true, message: '请上传车辆照片', trigger: 'change'}],
                transportCertificatePhoto: [{required: true, message: '请上传运输证照片', trigger: 'change'}],
                licenseCarType: [{validator: validateChinese, trigger: 'blur'}],
                carOwner: [{required: true, message: '请输入车辆所有人', trigger: 'blur'}],
                useCharacter: [{validator: validateChart, trigger: 'blur'}],
                // licenseCarModel: [{validator: validateChart, trigger: 'blur'}],
                vin: [{validator: validateLettersOrNumber, trigger: 'blur'}],
                engineId: [{required: true, trigger: 'blur', message: '请输入发动机号'}],
                carRegisterDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                issueDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                seats: [{validator: validateNumber, trigger: 'blur'}],
                nextFixDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                carBrandModel: [{required: true, message: '请选择品牌型号', trigger: 'change'}],
                carColor: [{required: true, message: '请选择颜色', trigger: 'change'}],
                fuelType: [{required: true, message: '请选择燃油类型', trigger: 'change'}],
                engineDisplace: [{required: true, message: '请选择发动机排量', trigger: 'change'}],
                fixState: [{required: true, message: '请选择车辆检修状态', trigger: 'change'}],
                checkState: [{required: true, message: '请选择车辆年检状态', trigger: 'change'}],
                total: [{validator: validateNumber, trigger: 'blur'}],
                carCommercialType: [{required: true, message: '请选择车辆服务类型', trigger: 'change'}],
                feePrintId: [{validator: validateNumber, trigger: 'blur'}],
                gpsBrand: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsModel: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsImei: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsInstalDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                transAgency: [{validator: validateChart, trigger: 'blur'}],
                certificate: [{validator: validateChart, trigger: 'blur'}],
                transDateStart: [{required: true, message: '请选择日期', trigger: 'change'}],
                transDateStop: [{required: true, message: '请选择日期', trigger: 'change'}],
            },
            validateChart,
            validateNumber,
            validateLettersOrNumber,
            validateNumberRequire,
            imageLoading: -1
        };
    },
    components: {
        SectionContent,
        BrandModel,
        SeatModel,
        ImagePanel,
        FileUpload
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    //主件被加载完成
    mounted: function () {
        this.getServerCity();
        this.getSupplierList();
    },
    activated() {
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            const ret = await getCityData(this, "2");
            if (ret) this.serverCityList = ret;
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        selectBrand(val) {
            this.form.carType = parseInt(val.carType);
            const carModel = this.formatCarModel(val.carType);
            this.form.wheelbase = carModel.wheelbase || 0;
        },
        //OCR识别
        async ocr(photoUrl, side) {
            const ret = await this.http('/galaxyDriverApproveApi/doDrivingLicenseDistinguishOcr', {
                photoUrl: photoUrl + '?imageView2/0/w/640/q/75',
                side
            }, 'POST');
            if (ret.success && ret.data) {
                let res = ret.data;

                let form = {
                    ...this.form,
                    ...res
                };
                for (let key in form) {
                    if (key.indexOf('Date') !== -1 && new Date(form[key]).toString() === 'Invalid Date') {
                        form[key] = null;
                    }
                }
                if (res['carNumberPlate']) {
                    form.carNumberPlate = res['carNumberPlate'].substr(1, res['carNumberPlate'].length - 1);
                    form.provincial = res['carNumberPlate'].substr(0, 1)
                }
                this.form = form;

            } else
                return false;
        },
        //文件上传完成
        async handleImageUpload({id, url}) {
            try {
                const miniUrl = url + '?imageslim';
                switch (id) {
                    case 'carLicenseFacePhoto':
                        this.form[id] = miniUrl;
                        this.imageLoading = 1;
                        await this.ocr(url, 0)
                        break;
                    case 'carLicenseBackPhoto':
                        this.imageLoading = 2;
                        this.form[id] = miniUrl;
                        await this.ocr(url, 1)
                        break;
                    case 'carPhoto':
                        this.form[id] = miniUrl;
                        break;
                    case 'transportCertificatePhoto':
                        this.netForm[id] = miniUrl;
                        break;
                    case 'insurPhoto0':
                        this.netForm.userCarInsuranceDto[0].insurPhoto = miniUrl;
                        break;
                    case 'insurPhoto1':
                        this.netForm.userCarInsuranceDto[1].insurPhoto = miniUrl;
                        break;
                    case 'insurPhoto2':
                        this.netForm.userCarInsuranceDto[2].insurPhoto = miniUrl;
                        break;
                }
            } finally {
                this.imageLoading = -1;
            }
        },
        //提交
        handleSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    //需要上传网约信息 校验网约信息
                    if (this.isSubmitNetCarInfo)
                        this.$refs['netRule'].validate((valid) => {
                            if (valid) {
                                this.submit();
                            } else this.$alert('填写有误，请修改后重新提交', '提示', {
                                confirmButtonText: '确定',
                                type: 'error'
                            });
                        })
                    else
                        this.submit();
                } else this.$alert('填写有误，请修改后重新提交', '提示', {
                    confirmButtonText: '确定',
                    type: 'error'
                });
            })
        },
        async submit() {
            const {
                carLicenseFacePhoto,
                carLicenseBackPhoto,
                carPhoto
            } = this.form;
            const {transportCertificatePhoto} = this.netForm;
            let msg = '';
            if (!carLicenseFacePhoto)
                msg = '请上传行驶证正面'
            if (!carLicenseBackPhoto)
                msg = '请上传行驶证背面';
            if (!carPhoto)
                msg = '请上传车辆照片';
            if (this.isSubmitNetCarInfo && !transportCertificatePhoto)
                msg = '请上传运输证照片';
            if (!msg)
                this.isEdit = false;
            this.$alert(msg || '信息填写完成，请再次核对您填写的信息，然后点击下方保存按钮', '提示', {
                confirmButtonText: '确定',
                type: msg ? 'error' : 'success'
            });
        },
        //保存数据
        async handleSave() {
            this.loadingShow();
            let form = {
                ...this.form,
                ...this.netForm
            };
            form.carBrand = form.carBrandModel[0]
            form.carModel = form.carBrandModel[1]
            delete form.carBrandModel;
            form.carNumberPlate = form.provincial + form.carNumberPlate;
            if (!form.total)
                form.total = 30000;
            if (!form.gpsBrand)
                form.gpsBrand = '汇星行'
            if (!form.gpsModel)
                form.gpsModel = 'VGC-8'
            if (!form.gpsImei)
                form.gpsImei = ''
            const ret = await this.http('/galaxyDriverApproveApi/supplierAddCarInfo', form, 'POST');
            this.loadingHide();
            if (ret.success && ret.data) {
                this.$message.success('添加司机成功');
                this.form = {
                    ...initForm,
                    carSupplierId: this.form.carSupplierId
                }
                this.netForm = {
                    ...initNetForm,
                    userCarInsuranceDto: [...userCarInsuranceDto]
                }
                this.isEdit = true;
            }
        },
        //获取供应商列表
        async getSupplierList() {
            const {userType, refId, companyName} = this.userInfo;
            let ret = null;
            this.disabledSupplier = false;
            switch (userType) {
                //总部
                case '0':
                    ret = await getAllSupplier(this, {});
                    if (ret.success && ret.data) {
                        this.supplierList = ret.data;
                    }
                    break;
                //供应商
                case '1':
                    this.supplierList = [{
                        supplierName: companyName,
                        id: refId
                    }];
                    this.form.carSupplierId = refId;
                    this.disabledSupplier = true;
                    break;
                //运营商
                case '2':
                    ret = await this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', {operatorId: refId}, 'POST');
                    if (ret.success && ret.data) {
                        this.supplierList = ret.data;
                    }
                    break;
            }
        }
    },
    //监听模版变量
    watch: {
        'form.carNumberPlate': function (n) {
            if (n) {
                this.form.carNumberPlate = n.toUpperCase();
                this.form.plateColor = n.length === 7 ? plateColors[1] : plateColors[0]
            }
        },
        'form.issueDate': function (n) {
            if (n && !this.form.gpsInstalDate)
                this.form.gpsInstalDate = n;
        }
    }
};
</script>
