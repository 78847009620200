import {onlyChineseAndLetter, isCellphone,
    isIDCardNumber, isSpecialChart, onlyNumber,
    onlyLettersOrNumber, isCarNumberPlate
} from "@/common/js/Utils";

export const validateName = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('内容不能为空,请输入'));
    } else if (!onlyChineseAndLetter(value)) {
        callback("格式错误");
    } else callback();
};

export const validatePhone = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('请输入手机号'));
    } else if (!isCellphone(value)) {
        callback("格式错误");
    } else callback();
};
export const validateId = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('请输入身份证号'));
    } else if (!isIDCardNumber(value)) {
        callback("格式错误");
    } else callback();
};
export const validateChart = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('内容不能为空,请输入'));
    } else if (!isSpecialChart(value)) {
        callback("格式错误");
    } else callback();
};
export const validateChinese = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('内容不能为空,请输入'));
    } else if (!isSpecialChart(value)) {
        callback("格式错误");
    } else callback();
};
export const validateNumberRequire = (rule, value, callback) => {
    if (value === '' || !value) {
        callback('内容不能为空，请输入');
    } else if (!onlyNumber(value)) {
        callback("格式错误");
    } else callback();
};
export const validateNumber = (rule, value, callback) => {
    if (value === '' || !value) {
        callback();
    } else if (!onlyNumber(value)) {
        callback("格式错误");
    } else callback();
};
export const validateLettersOrNumber = (rule, value, callback) => {
    if (value === '' || !value) {
        callback(new Error('内容不能为空,请输入'));
    } else if (!onlyLettersOrNumber(value)) {
        callback("格式错误");
    } else callback();
};
export const validatePlate = (rule, value, callback) => {
    console.log(value);
    if (value === '' || !value) {
        callback(new Error('内容不能为空,请输入'));
    } else if (!isCarNumberPlate(value)) {
        callback("格式错误");
    } else callback();
};
export const getStatusInfo = function (status) {
    const statusList = [
        {label: "未提交", value: 0, class: "verify-refuse"},
        {label: "初审", value: 1, class: "verify-default"},
        {label: "复审", value: 4, class: "verify-default1"},
        {label: "已通过", value: 2, class: "verify-success"},
        {label: "未通过", value: 3, class: "verify-refuse"},
        {label: "未提交", value: 5, class: "verify-refuse"}
    ]
    const info = statusList.find(item => item.value === status)
    return info || {};
};
