<style lang="scss" scoped>

</style>
<template>
    <div>
        <el-form :inline="true" :model="form" size="small">
            <el-form-item label="">
                <el-input v-model="form.fleetName" placeholder="输入车队名" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="userInfo.userType!=1">
                <supplier-info-input
                        :dataType="1"
                        :companyName="form.companyName"
                        @update:getSelectSupplier="getSelectSupplier"
                        @update:clearSupplier="clearSupplier"
                        clearable>
                </supplier-info-input>
            </el-form-item>
            <el-form-item v-if="userInfo.userType!=1">
                <operator-info-input
                        @update:getSelectOperator="getSelectOperator"
                        @update:clearOperator="clearOperator"
                        clearable>
                </operator-info-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchFleetInfo()">搜索</el-button>
            </el-form-item>
        </el-form>
    </div>

</template>

<script type="text/ecmascript-6">

    import SupplierInfoInput from "../../../../../components/supplierInfoInput/index"
    import OperatorInfoInput from "../../../../../components/operatorInfoInput/index"

    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    companyName: '',
                    supplierInfo: {},
                    operatorInfo: {},
                },
            }
        },
        props: {},
        components: {
            SupplierInfoInput,
            OperatorInfoInput
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            }
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            getSelectSupplier(item) {
                this.form.supplierInfo = item
            }, // 供应商
            getSelectOperator(item) {
                this.form.operatorInfo = item
            },  // 运营商
            searchFleetInfo() {
                this.$emit('update:searchFleetInfo', this.form)
            },
            clearSupplier() {
                this.form.supplierInfo = {}
            },  // 清除供应商内容
            clearOperator() {
                this.form.operatorInfo = {}
            }  // 清除运营商内容
        },
        //监听模版变量
        watch: {}

    }
</script>

