<style lang="scss" scoped>

</style>


<template>
    <div>
        <el-table
            :data="list"
            style="width: 100%">
            <el-table-column
                label="登记日期时间"
                width="170">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="driverName"
                label="姓名"
                width="100">
            </el-table-column>
            <el-table-column
                prop="driverMobile"
                label="手机号码"
                width="130">
            </el-table-column>
            <el-table-column
                prop="cityName"
                label="营运城市"
                width="120">
            </el-table-column>
            <el-table-column
                prop="supplierName"
                label="供应商"
                min-width="120">
            </el-table-column>
            <el-table-column
                prop="carNumberPlate"
                label="绑定车辆"
                min-width="120">
            </el-table-column>
            <el-table-column
                label="登记状态">
                <template slot-scope="scope">
                    {{ scope.row.isAbnormal | formatDesc(isAbnormalItem) }}
                </template>
            </el-table-column>
            <el-table-column
                label="7日核酸检测"
                min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.nucleicAcidStatus | formatDesc(nucleicAcidStatus) }}
                    <look-big-images
                        v-if="scope.row.nucleicAcidUrl"
                        title="查看"
                        :id="'healthCodeUrl' + scope.index"
                        :imagesUrl="scope.row.nucleicAcidUrl">
                    </look-big-images>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="疫测达抗原检测报告"
                min-width="90">
                <template slot-scope="scope">
                    {{ scope.row.agStatus | formatDesc(nucleicAcidStatus) }}
                    <look-big-images
                        v-if="scope.row.agUrl"
                        title="查看"
                        :id="'agUrl' + scope.index"
                        :imagesUrl="scope.row.agUrl">
                    </look-big-images>
                </template>
            </el-table-column>
            <el-table-column
                label="健康码"
                min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.healthCodeStatus | formatDesc(healthCodeItem) }}
                    <look-big-images
                        v-if="scope.row.healthCodeStatus !== 0 && scope.row.healthCodeUrl"
                        title="查看"
                        :id="'healthCodeUrl' + scope.index"
                        :imagesUrl="scope.row.healthCodeUrl">
                    </look-big-images>
                </template>
            </el-table-column>
            <el-table-column
                label="体温是否正常">
                <template slot-scope="scope">
                    {{ scope.row.temperatureStatus | formatDesc(temperatureItem) }}
                </template>
            </el-table-column>
            <el-table-column
                label="是否消毒">
                <template slot-scope="scope">
                    {{ scope.row.isDisinfect | formatDesc(isDisinfectItem) }}
                </template>
            </el-table-column>
            <el-table-column
                label="车辆场所码">
                <template slot-scope="scope">
                    <look-big-images
                        v-if="scope.row.carCodeUrl"
                        title="查看"
                        :id="'carCodeUrl' + scope.index"
                        :imagesUrl="scope.row.carCodeUrl">
                    </look-big-images>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script type="text/ecmascript-6">
import LookBigImages from '@/components/look-big-images/index'
import {
    healthCodeStatusItem,
    nucleicAcidStatus,
    temperatureStatusItem,
    isAbnormalItem,
    isDisinfectItem
} from '../../data/index'

export default {
    name: '',
    data() {
        return {
            healthCodeItem: healthCodeStatusItem,
            temperatureItem: temperatureStatusItem,
            nucleicAcidStatus,
            isAbnormalItem,
            isDisinfectItem
        }
    },
    props: {
        list: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        LookBigImages
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
    },
    methods: {},
    watch: {},
}
</script>
