<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <SectionContent style="background-color: #fff">
                <el-table style="width: 100%;" border v-loading="loading" :data="dataSource">
                    <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
                    <el-table-column label="标题" align="center" prop="title"></el-table-column>
                    <el-table-column label="副标题" align="center" prop="titleDetail"></el-table-column>
                    <el-table-column label="城市" align="center" prop="cityName"></el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" style="width: 80px"
                                       @click="handleDetail(1,scope.row)">
                                详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </SectionContent>
        </el-main>
        <!--        <el-footer style="display: flex;justify-content: flex-end;align-items: center">-->
        <!--            <el-pagination background layout="prev, pager, next"-->
        <!--                           :current-page="form.pageIndex"-->
        <!--                           :total="result.total"-->
        <!--                           @current-change="getList">-->
        <!--            </el-pagination>-->
        <!--        </el-footer>-->
    </div>
</template>

<script>
import SectionContent from "@/components/sectionContent/index.vue";
import {getLibraryDriverQuestionInfoListApi} from "@/www/urls/driverQuestion";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
})

export default {
    name: "PreJobQuestion",
    data() {
        return {
            loading: false,
            form: createForm(),
            dataSource: [],
            result: {
                data: [],
                total: 0
            }
        }
    },
    components: {
        SectionContent
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getLibraryDriverQuestionInfoListApi(this.form)
            this.loading = false
            if (ret.success)
                this.dataSource = ret.data;
            else
                this.$message.error(ret.errors[0].message)

        },
        handleDetail(type, record) {
            this.$router.push({
                path: '/preJobAnswer',
                query: {
                    id: record.id,
                    title: `${record.title}${record.titleDetail}`
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
    margin-top: 10px;
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
