<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="dc-export">
                <div style="background-color: #fff;padding: 0 10px">
                    <el-form :inline="true" :model="form" label-width="70px" label-position="left">
                        <el-form-item label="导出状态">
                            <el-radio-group v-model="form.status">
                                <el-radio-button label="-1">全部</el-radio-button>
                                <el-radio-button :label="vm.value" v-for="vm in statusOptions" :key="vm.value">
                                    {{ vm.label }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="导出城市">
                            <SelectCity ref="selectCity" size="default" :is-border="false"
                                        @update:city="form.cityId=$event.shortCode"></SelectCity>
                        </el-form-item>
                        <el-form-item style="float: right">
                            <el-button @click="reset()">重置</el-button>
                            <el-button type="primary" @click="getData()">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table border :data="list" style="width: 100%;margin: 10px 0">
                    <el-table-column type="index"></el-table-column>
                    <el-table-column prop="carNumberPlate" label=" 状态" width="120">
                        <template slot-scope="scope">
                            <div style="display: flex;flex-direction: column;align-items: center">
                                <el-tag :type="scope.row.status| formatDesc(statusOptions,'value','type')">
                                    {{ scope.row.status| formatDesc(statusOptions) }}
                                </el-tag>
                                <el-popover
                                    v-if="[3].includes(scope.row.status)"
                                    placement="top-start"
                                    width="200"
                                    trigger="hover">
                                    <p class="color-error">{{ scope.row.refuseInfo }}</p>
                                    <i slot="reference" style="cursor: pointer"
                                       class="icon icon-not-pass color-error"></i>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请日期" width="140">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | dateCus('yyyy-MM-dd hh:mm') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="导出条件">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type===1">
                                司机基本信息 =
                                <b v-if="scope.row.driverAuditStatus!==undefined">
                                    {{ formatStatus(scope.row.driverAuditStatus).label }}
                                </b>
                                <b v-else>全部</b>，
                                司机备案状态 =
                                <b v-if="scope.row.driverFlagStatus!==undefined">
                                    {{
                                        scope.row.driverFlagStatus | formatDesc(flagStatusOptions)
                                    }}
                                </b>
                                <b v-else>全部</b>
                                ，司机网约信息 =
                                <b v-if="scope.row.driverCertificateAuditStatus!==undefined">{{
                                        formatStatus(scope.row.driverCertificateAuditStatus).label
                                    }}</b>
                                <b v-else>全部</b>
                            </div>
                            <div v-if="scope.row.type===2">
                                车辆基本信息 =
                                <b v-if="scope.row.carAuditStatus!==undefined">{{
                                        formatStatus(scope.row.carAuditStatus).label
                                    }}</b>
                                <b v-else>全部</b>
                                ，备案状态 =
                                <b v-if="scope.row.carFlagStatus!==undefined">{{
                                        scope.row.carFlagStatus | formatDesc(flagStatusOptions)
                                    }}</b>
                                <b v-else>全部</b>
                                ，车辆网约信息 =
                                <b v-if="scope.row.carOperationAuditStatus!==undefined">{{
                                        formatStatus(scope.row.carOperationAuditStatus).label
                                    }}</b>
                                <b v-else>全部</b>
                            </div>
                            <div>
                                司机是否带车 =
                                <b v-if="scope.row.personalCar">{{ scope.row.personalCar === 0 ? '否' : '是' }}</b>
                                <b v-else>全部</b>
                                ，车型 = <b v-if="scope.row.carType">{{ ~~scope.row.carType | seatModelDesc }}</b>
                                <b v-else>全部</b>
                                ，城市 = <b v-if="scope.row.cityName||scope.row.transArea">{{
                                scope.row.cityName || scope.row.transArea
                              }}</b>
                                <b v-else>全部</b>
                                ，是否可用 = <b v-if="scope.row.isValid!==undefined">{{
                                    scope.row.isValid === 1 ? '可用' : '不可用'
                                }}</b>
                                <b v-else>全部</b>
                                ，供应商 = <b v-if="scope.row.supplierName">{{ scope.row.supplierName }}</b>
                                <b v-else>全部</b>
                                ，车队名称 = <b v-if="scope.row.driverFleetName">{{ scope.row.driverFleetName }}</b>
                                <b v-else>全部</b>
                                ，注册日期范围 =
                                <b v-if="scope.row.createTimeStart">{{ scope.row.createTimeStart | dateCus }} -
                                    {{ (scope.row.createTimeEnd-1) | dateCus }}</b>
                                <b v-else>全部</b>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请人-审核人" width="160">
                        <template slot-scope="scope">
                            {{
                                scope.row.createName
                            }}{{ scope.row.confirmLoginName ? ' - ' + scope.row.confirmLoginName : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" align="center">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status===0&&scope.row.createLoginId!==userInfo.loginuserId"
                                       type="primary" size="small"
                                       :loading="loading"
                                       @click="handleToExamine(scope.row)">审核
                            </el-button>
                            <el-button v-if="scope.row.createLoginId===userInfo.loginuserId&&scope.row.status===1"
                                       type="success" size="small"
                                       :loading="loading"
                                       @click="handleDownload(scope.row)">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="pageChange"
                        :current-page.sync="form.pageIndex"
                        :page-size="form.pageSize"
                        layout="total, prev, pager, next"
                        :total="total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="visible" title="审核" width="500px">
            <el-form :model="examineForm" label-width="100px" ref="form">
                <el-form-item label="是否通过：">
                    <el-radio-group v-model="examineForm.status">
                        <el-radio label="1">通过</el-radio>
                        <el-radio label="3">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="examineForm.status==='3'" label="理由：" prop="refuseInfo"
                              :rules="[{required:true,message:'请输入拒绝理由'}]">
                    <el-input style="width: 90%" v-model="examineForm.refuseInfo" type="textarea"
                              placeholder="请输入拒绝理由"/>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="visible=false">取消</el-button>
                <el-button type="primary" :loading="loading" @click="handleSaveExamine">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">

import {
    downloadDriverAndCarExportApi,
    driverAndCarExportListApi,
    updateDriverAndCarExportInfoApi
} from "@/www/urls/driverManage";
import SelectCity from "@/components/selectCity/index.vue";
import {getStatusInfo} from "@/www/pages/driverManage/service";
import {download} from "@/common/js/Utils";
import dayjs from "dayjs";

export default {
    //定义模版数据
    name: "driverAndCarExport",
    data() {
        return {
            loading: false,
            visible: false,
            current: null,
            form: {
                pageIndex: 1,
                pageSize: 10,
                status: '-1'
            },
            list: [],
            total: 0,
            flagStatusOptions: [
                {
                    label: '未备案',
                    value: 0
                },
                {
                    label: '已备案',
                    value: 1
                },
                {
                    label: '备案失败',
                    value: 2
                }
            ],
            statusOptions: [
                {
                    label: '待审核',
                    value: 0,
                    type: 'primary'
                },
                {
                    label: '审核未通过',
                    value: 3,
                    type: 'danger'
                },
                {
                    label: '审核通过',
                    value: 1,
                    type: 'success'
                }
            ],
            examineForm: {
                status: '1',
                refuseInfo: ''
            }
        };
    },
    components: {
        SelectCity
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.loadingShow();
                const ret = await driverAndCarExportListApi({
                    ...this.form,
                    status: this.form.status === '-1' ? undefined : this.form.status
                })
                if (ret.success) {
                    this.list = ret.data.data;
                    this.total = ret.data.total
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loadingHide();
            }
        },
        pageChange(page) {
            this.form.pageIndex = page;
            this.getData()
        },
        reset() {
            this.$refs['selectCity'].clear()
            this.form.cityId = undefined
            this.form.status = '-1'
            this.getData()
        },
        formatStatus(status) {
            let info = getStatusInfo(status);
            return info;
        },
        handleToExamine(record) {
            this.examineForm.status = '1'
            this.examineForm.refuseInfo = ''
            this.visible = true;
            this.current = record;
        },
        async handleSaveExamine() {
            try {
                this.loading = true
                await this.$refs['form'].validate()
                await this.$confirm('确定提交？', '提示');
                const ret = await updateDriverAndCarExportInfoApi({
                    id: this.current.id,
                    ...this.examineForm,
                    refuseInfo: this.examineForm.refuseInfo || undefined
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.visible = false
                    this.getData()
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loading = false
            }
        },
        async handleDownload({id, type}) {
            try {
                this.loading = true;
                const ret = await downloadDriverAndCarExportApi({id})
                if (ret.success) {
                    if (ret.data) {
                        download(ret.data, (type === 1 ? '司机' : '车辆') + '列表导出-' + dayjs().format('YYYY-MM-DD HH:mm:ss'))
                        this.$message.success('文件正在下载...')
                    } else {
                        this.$alert('当前条件下无数据', '提示')
                    }
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
};
</script>

