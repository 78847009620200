<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <com-list-search>
                <el-form :inline="true" size="medium" slot="list-search-before">
                    <el-form-item>
                        <el-radio-group v-model="form.status">
                            <el-radio-button v-for="vm in status" :label="vm" :key="vm"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="list-search-after">
                    <el-button @click="resetClick()">重置</el-button>
                    <el-button type="primary" @click="getList()" :loading="loading">搜索</el-button>
                </div>
            </com-list-search>
            <SectionContent style="background-color: #fff">
                <el-table style="width: 100%;" border v-loading="loading" :data="result.data">
                    <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
                    <el-table-column label="司机名称" align="center" prop="driverName"></el-table-column>
                    <el-table-column label="司机手机号" align="center" prop="driverMobile"></el-table-column>
                    <el-table-column label="答题时间" align="center" prop="questionTime">
                        <template slot-scope="scope">
                            {{ scope.row.questionTime | dateCus }}
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.questionTime?'success':'warning'">
                                {{ scope.row.questionTime ? '已完成' : '未完成' }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </SectionContent>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="total,prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
    </div>
</template>

<script>
import SectionContent from "@/components/sectionContent/index.vue";
import {getLibraryDriverQuestionBankInfoListApi} from "@/www/urls/driverQuestion";
import comListSearch from "../../../components/comListSearch/index.vue";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    status: "全部"
})

export default {
    name: "PreJobAnswer",
    data() {
        return {
            loading: false,
            status: ["全部", "已完成", "未完成"],
            form: createForm(),
            result: {
                data: [],
                total: 0
            }
        }
    },
    components: {
        SectionContent,
        comListSearch
    },
    mounted() {
        this.getList();
        this.$store.commit('', this.$route.query.title)
    },
    methods: {
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.loading = true
            const ret = await getLibraryDriverQuestionBankInfoListApi({
                ...this.form,
                questionId: this.$route.query.id,
                status: this.status.indexOf(this.form.status)
            })
            this.loading = false
            if (ret.success)
                this.result = ret.data;
            else
                this.$message.error(ret.errors[0].message)

        },
        handleDetail(type, record) {
            this.$router.push({
                path: '/messageAdd',
                query: {
                    id: record.id,
                    type
                }
            })
        },
        resetClick() {
            this.form = createForm()
        }
    }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
    margin-top: 10px;
}

.message-info-btn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
