/**
* Created by tangdq on 2018/8/20.
*/
<style lang="scss" scoped>
.audit-list {
    .audit-list-screen {
        background: #ffffff;
        /*padding: 20px 20px 20px 20px;*/
        .common-list-search {
            padding-bottom: 5px;
        }

    }

    .screen-operate-btn {
        position: absolute;
        right: 10px;
        bottom: 10px;

    }

    .audit-list-table {
        background: #fff;
        margin-top: 10px;
        padding: 0 20px;

        .not-through-popover {
            display: inline-block;
            cursor: pointer;

            .el-popover__reference {
                color: #FF3B30;
            }
        }
    }

    .list-screen-btn {
        position: relative;
        /*height: 40px;*/
    }

    .show-new {
        font-size: 10%;
        color: #ff3b30;
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px
    }
}

.audit-list /deep/ {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 180px);
        }

        .list-search-after {
            width: 180px;
            text-align: right;
        }
    }
}

</style>
<style lang="scss">
.audit-list {
    .audit-list-screen {

        .el-form-item {
            margin-bottom: 10px;
            display: inline-block;
            /*float: left;*/

        }

        .radio-change-date {
            width: 280px;
        }

        .radio-change-group {
            .el-form-item__content {
                height: 32px;
                line-height: 32px;
            }
        }

        .toastCount {
            height: 40px;
            background: #E6F7FF;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 10px;

            i {
                color: #319BF7;
                padding-right: 10px
            }

            span {
                color: #319BF7;
                font-weight: bold;
            }
        }

        .refuseButton {
            color: #319BF7;
            border-color: #319BF7
        }

        .auditCol {
            height: 70px;
            padding: 0 20px;
            border-bottom: 1px solid #EBEEF5;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .auditName {
                p {
                    span {
                        font-size: 14px;
                        color: #000B15;
                    }
                }

            }

        }
    }

    .screen-radio-change {
        .el-radio-button:first-child {
            .el-radio-button__inner {
                padding: 9px 12px;
            }
        }

        .el-radio-button__inner {
            padding: 9px 10px;
        }

        .screen-contract-effective {
            .el-radio-button__inner {
                padding: 9px 18px;
            }
        }
    }

    .table-info-operation {
        .el-button {
            padding: 0;
        }
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont audit-list">
                <div class="audit-list-screen">
                    <com-list-search>
                        <div slot="list-search-before">
                            <div class="screen-radio-change clear">
                                <el-form :inline="true" label-position="right" size="small">
                                    <el-form-item label-width="100px" style="width: 250px">
                                        <el-input placeholder="分销商名称" v-model="subForm.merchantName"></el-input>
                                    </el-form-item>
                                    <el-form-item v-if="['0','2'].includes(userInfo.userType)" label-width="100px"
                                                  style="width: 250px;margin-left: 10px">
                                        <el-input placeholder="供应商名称" v-model="subForm.supplierName"></el-input>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div slot="list-search-after">
                            <el-button @click="screenResetClick()">重置</el-button>
                            <el-button type="primary" @click="getList()">搜索</el-button>
                        </div>

                    </com-list-search>
                </div>
                <div class="audit-list-screen" v-if="approvalTotalCount && auditAccountList"
                     style="margin-bottom: 10px">
                    <p class="toastCount"><i
                        class="el-icon-warning toastCount-icon"></i>以下 <span>{{ approvalTotalCount }}</span> 条
                        合作信息，请尽快处理。</p>
                    <div>
                        <div class="auditCol" v-for="(item,index) in auditAccountList" :key="index"
                             v-show="index<len">
                            <div class="auditName">
                                <span class="color-primary">{{ item.merchantName }} </span>申请成为合作机构
                            </div>
                            <div style="float: right">
                                <el-button class="refuseButton" size="mini" @click="handleApproval(item,2)">拒绝
                                </el-button>
                                <el-button type="primary" size="mini" @click="handleApproval(item,1)">通过</el-button>
                            </div>
                        </div>
                        <div v-if="auditAccountList.length>2">
                            <el-button type="text" style="color: #909399;" v-if="len==2"
                                       @click="len=auditAccountList.length">
                                查看全部<i class="el-icon-caret-bottom el-icon--right"></i>
                            </el-button>
                            <el-button type="text" style="color: #909399;" v-else @click="len=2">
                                收起<i class="el-icon-caret-top el-icon--right"></i>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="audit-list-table">
                    <p style="padding: 16px 0;">已绑定的合作机构：</p>
                    <el-table class="table-info" border style="width: 100%" :height="tableH"
                              v-loading="comListTableLoading" :data="loginAccountList">
                        <el-table-column label="分销商名称" prop="merchantName"></el-table-column>
                        <el-table-column label="供应商名称" prop="supplierName">
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            :class-name="('table-info-operation')"
                            width="100">
                            <template slot-scope="scope">
                                <el-button @click="handleUnbind(scope.row)"
                                           type="text" v-if="['0','2'].includes(userInfo.userType)">解绑
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="table-page-number" v-if="loginAccountList && loginAccountList.length > 0">
                        <div class="block">
                            <el-pagination
                                @current-change="handleTablePageNumberChange"
                                :page-size.sync="subForm.pageSize"
                                layout="total, prev, pager, next"
                                :total="loginAccountList.length">
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </el-main>
    </div>


</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../components/comListSearch/index.vue'

export default {
    name: 'fleetBindVerify',
    //定义模版数据
    data() {
        return {
            tableH: 'calc(100vh - 312px)',
            subForm: {
                merchantName: undefined,
                supplierName: undefined,
                pageIndex: 1,
                pageSize: 10,
            },
            approvalTotalCount: 0,
            loginAccountListTotalCount: 0,
            comListTableLoading: false,
            loginAccountList: [],
            auditAccountList: [],
            len: 2,
        }
    },
    components: {comListSearch},
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    activated() {
        this.getList();
    },
    methods: {
        screenResetClick() {  // 重置
            this.subForm = {pageIndex: 1, pageSize: 10,}
            this.getList();
        },
        handleTablePageNumberChange() {
            this.getList();
        },
        async getList() {
            try {
                let res = await this.http('/galaxyOrgInfoApi/cooperateOrgList', this.subForm, 'POST');
                if (res.success && res.data) {
                    this.auditAccountList = res.data.userCooperateOrgDtos;
                    this.loginAccountList = res.data.userCooperateOrgDtoPageList.data;
                    this.loginAccountListTotalCount = res.data.userCooperateOrgDtoPageList.total;
                    this.approvalTotalCount = this.auditAccountList.length;
                }
            } catch (e) {
                console.log(e);
                this.messageError();
            }
        },
        async handleApproval(item, status) {
            try {
                await this.$confirm(status === 1 ? '确定通过？' : '确定拒绝？', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                let res = await this.http('/galaxyOrgInfoApi/updateCooperateOrg', {
                    id: item.id,
                    status
                }, 'POST');
                if (res.success) {
                    this.$message({message: '操作成功!', type: 'success'})
                    this.getList();
                }
            } finally {
                console.log(2332)
            }
        },
        async handleUnbind(item) {
            try {
                await this.$confirm('确定解绑', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                let res = await this.http('/galaxyOrgInfoApi/updateCooperateOrg', {
                    id: item.id,
                    isDelete: 1
                }, 'POST');
                if (res.success) {
                    this.$message({
                        message: '解绑成功!',
                        type: 'success'
                    })
                    this.getList();
                }
            } finally {
                console.log(2332)
            }
        }
    }
}
</script>

