<template>
    <el-popover placement="top" width="240" v-model="visible" title="修改下次年检日期">
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="" prop="nextFixDate">
                <el-date-picker v-model="form.nextFixDate"
                                type="date"
                                clearable
                                placeholder="请选择下次年检日期"
                                value-format="yyyy/MM/dd">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="submit" :loading="loading">确定</el-button>
        </div>
        <el-button style="width: 60px" slot="reference" type="warning" size="mini" class="ml10">编辑</el-button>
    </el-popover>
</template>

<script>
export default {
    name: "EditPopover",
    data () {
        return {
            loading: false,
            visible: false,
            form: {},
            rules: {
                nextFixDate: [
                    {
                        required: true,
                        message: '请选择下次年检日期'
                    }
                ]
            }
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({})
        },
        submitApi: {
            type: Function,
            default: null
        }
    },
    methods: {
        async submit () {
            await this.$refs['form'].validate();
            this.loading = true
            console.log(this.submitApi);
            let res = await this.submitApi(this.form);
            if (res)
                this.visible = false
            this.loading = false;
        }
    },
    watch: {
        info: {
            handler (n = {}) {
                this.form = {
                    ...n
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>