<style lang="scss" scoped>
    .com-fleet-info {
        margin-bottom: 20px;

        h5 {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .fleet-info-btn_add {
            width: 82px;
            margin-bottom: 10px;
        }

        .fleet-info-list {
            display: flex;
            font-size: 14px;
            margin-bottom: 10px;

            ul {
                display: flex;
                line-height: 50px;
                background: #F0F2F5;
                border-radius: 4px;
            }

            li {
                padding: 0 10px;

                &.fleet-info-list1 {
                    min-width: 30px;
                    text-align: center;
                }

                &.fleet-info-list2 {
                    min-width: 60px;
                }

            }

            .fleet-info-btn_del {
                margin-left: 20px;
                color: #FF3B30;
            }
        }
    }
</style>
<template>

    <div>
        <div class="com-fleet-info">
            <h5>{{addType === 1 ? '司机' : '车辆'}}信息</h5>
            <el-button class="fleet-info-btn_add" size="small" type="primary" @click="handleAddDriver(1)"
                       v-if="addType === 1 && !isEdit">+ 司机
            </el-button>
            <el-button class="fleet-info-btn_add" size="small" type="primary" @click="handleAddDriver(2)"
                       v-if="addType === 2 && !isEdit">+ 车辆
            </el-button>
            <div class="fleet-info-list" v-for="(item, index) in list" :key="index">
                <ul v-if="addType === 1">
                    <li class="fleet-info-list1">{{index + 1}}</li>
                    <li style="width: 80px">{{item.driverName}}</li>
                    <li style="width: 100px">{{item.driverMobile}}</li>
                </ul>
                <ul v-if="addType === 2">
                    <li class="fleet-info-list1">{{index + 1}}</li>
                    <li style="width: 80px;">{{item.carNumberPlate}}</li>
                    <li style="width: 100px;">{{item.carBrand}} {{item.carModel}}</li>
                    <li style="width: 80px;" >{{item.name | seatModelDesc}}
                    </li>
                    <li style="width: 50px;">{{item.carColor}}</li>
                </ul>
                <el-button class="fleet-info-btn_del" size="small" v-if="!isEdit" type="text"
                           @click="handleDelete(item)">删除
                </el-button>
            </div>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {}
        },
        props: {
            addType: {
                type: Number,
                default: null
            },  // 1 司机  2车辆
            fleetInfoList: {
                type: Array,
                default: function () {
                    return [];
                }
            },  // 添加详情
            isEdit: {
                type: Boolean,
                default: false
            }  // 是否编辑
        },
        components: {},
        //计算属性
        computed: {
            list() {
                return this.fleetInfoList
            },
        },
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            handleAddDriver(type) {
                this.$emit('update:getAddDriver', type);
            },  // 添加司机或车辆按钮
            handleDelete(item) {
                this.$emit('update:getDelete', {type: this.addType, deleteInfo: item});
            }  // 删除

        },
        //监听模版变量
        watch: {}

    }
</script>

