<style lang="scss" scoped>

</style>
<template>

    <div class="product-list-table el-table_border_none">
        <el-table
            type="index"
            class="table-info"
            border
            :data="fleetList"
            style="width: 100%">
            <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
            <el-table-column prop="fleetName" label="类型" width="160" align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.idCardNumber?'primary':'success'">
                        {{ scope.row.idCardNumber ? '司机' : '车辆' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="cityName" align="center" label="城市"></el-table-column>
            <el-table-column label="身份证" width="200" align="center">
                <template slot-scope="scope">
                    {{ scope.row.idCardNumber }}
                </template>
            </el-table-column>
            <el-table-column label="车牌号" width="160" align="center">
                <template slot-scope="scope">
                    {{ scope.row.carNumberPlate }}
                </template>
            </el-table-column>
            <el-table-column prop="reason" label="原因" align="center"></el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleDel(scope.row)" size="small" type="danger">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        let data
        return {
            data
        }
    },
    props: {
        fleetList: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    //定义事件方法
    methods: {
        handleDel(record) {
            this.$emit('onDel', record)
        }

    }
}
</script>

