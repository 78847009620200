<style lang="scss" scoped>

</style>

<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <anti-epidemic-form @search="handleSearch" ref="searchForm"></anti-epidemic-form>
                <anti-epidemic-table :list="list" class="mt10"></anti-epidemic-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <pagination-foot
                ref="paginationFoot"
                :total="pageInfo.total"
                @update:pageInfo="handlePageInfo">
            </pagination-foot>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import AntiEpidemicForm from './components/form'
import AntiEpidemicTable from './components/table'
import PaginationFoot from "@/components/pagination-foot/index";

export default {
    name: '',
    data() {
        return {
            list: [],
            form: {},
            pageInfo: {},
        }
    },
    components: {
        AntiEpidemicForm,
        AntiEpidemicTable,
        PaginationFoot
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {

    },
    methods: {
        handleSearch(data){
            this.form = data
            this.$refs.paginationFoot.changeCurrent(1)
        },
        handlePageInfo(pageInfo) {
            this.pageInfo = {
                ...this.pageInfo,
                ...pageInfo
            }
            this.handleList();
        },
        // 后台查询司机的每日健康记录
        async handleList() {
            let {
                pageSize,
                currentPage
            } = this.pageInfo
            let form = {
                ...this.form,
                pageSize,
                pageIndex: currentPage
            };
            const res = await this.http('/driverDailyHealth/getDriverDailyHealthInfoList', form, 'POST');
            if (res.success && res.data) {
                this.pageInfo.total = res.data.total;
                this.list = res.data.data
            }
        },
    },
    watch: {},
}
</script>
