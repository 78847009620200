<style lang="scss" scoped>
    .fleet-list {
        .fleet-list-inline {
            .el-form-item {
                width: 135px;
            }
        }

        .product-check-list {
            margin-top: 5px;

            .check-list-title {
                font-size: 14px;
                margin-right: 20px;
            }

            .check-list-group {
                display: inline-block;
            }
        }

        .fleet-list-table {
            background: #fff;
            margin-top: 10px;
            /*padding: 0 20px;*/
        }

        .search-show-product {
            display: inline;
        }
    }

    .fleet-list /deep/ {
        .common-list-search {
            padding-bottom: 20px;

            .list-search-before {
                .el-form-item.el-form-item--small {
                    width: auto;
                    /*min-width: 320px;*/
                    margin-bottom: 0;
                }

                .el-form-item__content {
                    width: auto;
                }

            }

            .list-search-after {

            }
        }

        .table-info {
            .el-table__fixed-right {
                right: 10px !important;
            }

            .el-table__fixed-right-patch {
                width: 10px !important;
            }
        }
    }
</style>
<style lang="scss">
    .upper-lower-lines-dialog {
        top: 50%;
        margin: auto;

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            text-align: center;
            font-size: 16px;
        }

        .el-dialog__footer {
            padding: 0 20px 20px 20px;
            text-align: center;
        }
    }

    .fleet-list-table {
        .operation-btn {
            .el-button {
                padding: 0;
                margin: 5px;
                margin-left: 0;
                float: left;
            }
        }
    }


</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="fleet-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <fleet-list-search
                                @update:searchFleetInfo="searchFleetInfo">
                        </fleet-list-search>
                    </div>
                    <div slot="list-search-after" style="text-align: right">
                        <el-button @click="handleAddFleet()" type="primary" style="width: 120px">添加车队</el-button>
                    </div>
                </com-list-search>
                <fleet-list-table style="margin-top: 10px"
                                  :fleetList="fleetList"
                                  :loading="listLoading"
                                  :userInfo="userInfo"
                                  @update:handleEditFleet="handleEditFleet"
                                  @update:handleEnable="handleEnable"
                                  @update:handleSeeFleet="handleSeeFleet"
                                  @update:handleShowLog="handleShowLog"
                >
                </fleet-list-table>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="searchFleetList"
                            :current-page.sync="pageInfo.pageIndex"
                            :page-size="pageInfo.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageInfo.total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>

        <is-enable-dialog
                :isEnableShow="isEnableShow"
                :enableTips="enableTips"
                @update:handleCancel="handleCancel"
                @update:handleEnableSubmit="handleEnableSubmit">
        </is-enable-dialog>
        <is-fleet-log-dialog
                :isFleetLogShow="isFleetLogShow"
                :fleetLogList="fleetLogList"
                :fleetLogLoading="fleetLogLoading"
                @update:handleLogClose="handleLogClose">
        </is-fleet-log-dialog>
    </div>
</template>


<script type="text/ecmascript-6">
    import comListSearch from "../../../components/comListSearch/index.vue";
    import SectionContent from "@/components/sectionContent/index.vue";

    import fleetDetailServer from '../../../urls/fleetDetailUrls'
    import fleetListTable from "./components/fleetListTable/index"
    import isEnableDialog from "./components/isEnableDialog/index"
    import isFleetLogDialog from "./components/isFleetLogDialog/index"
    import fleetListSearch from "./components/fleetListSearch/index"

    export default {
        //定义模版数据

        name: 'fleetList',
        data() {
            return {
                isEnableShow: false,
                form: {
                    companyName: '',
                    supplierInfo: {},
                    operatorInfo: {},
                },

                fleetList: [],
                enableTips: {
                    title: '',
                    fleetName: '',
                    index: '',
                    type: ''
                },

                listLoading: false,
                pageInfo: {
                    // 列表分页
                    pageSize: 10, // 每页显示条目个数
                    total: 1, // 总条目个数
                    pageIndex: 1 // 当前页数
                },
                supplierName: "",
                isFleetLogShow: false,
                fleetLogList: [],
                fleetLogLoading: false,

            };
        },
        components: {
            SectionContent,
            comListSearch,
            fleetListTable,
            isEnableDialog,
            isFleetLogDialog,
            fleetListSearch
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            }
        },
        activated: function(){
            this.searchFleetList(1)
        },
        //主件被加载完成
        mounted: function () {
            // this.searchFleetList(1)
        },
        //定义事件方法
        methods: {
            handleCancel(bool) {
                this.isEnableShow = bool;
                this.enableTips = {
                    title: '',
                    fleetName: '',
                    index: '',
                    type: ''
                }
            },
            handleEnable(info) {
                this.isEnableShow = true;
                this.enableTips = {
                    title: info.type === 0 ? '启用' : '停用',
                    fleetName: this.fleetList[info.index].fleetName,
                    index: info.index,
                    type: info.type
                }
            },

            async handleEnableSubmit() {
                let form = {
                    useStatus: this.enableTips.type,
                    id: this.fleetList[this.enableTips.index].id
                };
                let res = await fleetDetailServer.driverFleetUpdateUseStatus(this, form);
                if (res.success) {
                    this.$message.success("操作成功");
                    this.isEnableShow = false;
                    // this.searchFleetList()
                    let data = JSON.parse(JSON.stringify(this.fleetList));
                    data[this.enableTips.index].useStatus = this.enableTips.type;
                    this.fleetList = data
                    console.log(this.fleetList[0]);
                }
            },
            searchFleetInfo(info){
                console.log(info);
                console.log(info,"898989---799898989");
                this.form = info;
                this.searchFleetList(1)
            },

            async searchFleetList(index) {
                let form = {
                    pageIndex: index,
                    pageSize: this.pageInfo.pageSize
                }
                let {fleetName, supplierInfo, operatorInfo} = this.form;
                if(fleetName) form.fleetName = fleetName;
                if(supplierInfo) form.supplierId = supplierInfo.id;
                if(operatorInfo) form.operatorId = operatorInfo.id;
                let res = await fleetDetailServer.driverFleetPageList(this, form);
                console.log(res);
                if (res.success && res.data) {
                    this.fleetList = res.data.data;
                    this.pageInfo.total = res.data.total;
                }
            },

            // 查看车队
            handleSeeFleet(data) {
                this.$router.push(`/fleetSeeDetail/${data.id}/2`);
            },
            // 编辑车队
            handleEditFleet(data) {
                this.$router.push(`/fleetEditDetail/${data.id}/1`);
            },

            async handleShowLog(item) {
                this.fleetLogLoading = true;
                let res = await fleetDetailServer.selectDriverFleetLog(this, item.id);
                this.isFleetLogShow = true;
                if (res.success && res.data) {
                    this.fleetLogList = res.data
                    // if (res.data.length > 0) {
                    //     this.fleetLogList = res.data.sort((a, b) => {
                    //         return b.createTime - a.createTime
                    //     });
                    // }
                }
                this.fleetLogLoading = false;

            },
            handleAddFleet() {
                this.$router.push('/fleetDetail/0/0');
            },


            handleLogClose(bool) {
                this.isFleetLogShow = bool
            }
            // handleSelect(item) {
            //     console.log(item);
            //     this.supplierName = item.supplierName;
            //     this.form.supplierId = item.id;
            // },
            // handleSelectPlate(item) {
            //     this.form.carNumberPlate = item.carNumberPlate;
            // },
        },
        //监听模版变量
        watch: {}
    };
</script>

