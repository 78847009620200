<template>

    <div>
        <el-autocomplete
                style="width: 100%"
                v-model="fleetName"
                :fetch-suggestions="handleOperator"
                placeholder="输入车队名称"
                :trigger-on-focus="false"
                @select="selectOperator">
            <template slot-scope="{ item }">
                <div class="list-pop-bot">
                    <span>{{item.label}}</span>
                </div>
            </template>
        </el-autocomplete>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        name: "fleetNameInput",
        //定义模版数据
        data() {
            return {
                fleetName: ''
            }
        },
        props: {
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        activated() {
        },
        //定义事件方法
        methods: {
            filter(item) {
                return (res) => {
                    return res.fleetName.indexOf(item) === 0;
                };
            },
            async handleOperator(operator, cb) {
                let res = await this.http(`/galaxyDriverFleetApi/selectDriverFleetInfoByName/${this.fleetName}`);
                if (res && res.success) {
                    let list = [];
                    list = res.data;
                    list.forEach(item => {
                        item.value = item.id;
                        item.label = item.fleetName;
                    });
                    let operatorList = operator ? list.filter(this.filter(operator)) : list;
                    cb(operatorList);
                }
            },  // 车队列表
            selectOperator(item) {
                this.fleetName = item.fleetName;
                this.$emit('update:getSelectFleetName', item)
            },  // 车队列表选中
            clear(){
                this.fleetName = ''
            }
        },
        //监听模版变量
        watch: {
        }

    }
</script>

