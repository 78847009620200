<style lang="scss">
</style>
<template>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        @close="cancel"
        @open="handleOpen"
        width="30%">
        <el-form ref="form"
                 :model="form"
                 label-width="140px">
            <el-form-item label="选择供应商"
                          :rules="[{required: true, message: '请选择供应商', trigger: 'blur' }]">
                <ApiSelect style="width: 100%" v-model="form.supplierId"
                           :api="getSupplierListByOrgInfoApi"
                           :replace-field="{
                    label:'supplierName',
                    value:'id'
                }" filterable @change="handleChange"></ApiSelect>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
    </el-dialog>

</template>

<script type="text/ecmascript-6">
import {getSeatModel} from "@/data/index";
import {getSupplierListByOrgInfoApi} from '@/www/urls/driverManage'

export default {
    //定义模版数据
    data() {
        return {
            //司机转会
            form: {
                supplierId: null,
                supplier: {}
            },
            getSupplierListByOrgInfoApi
        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
            value: false
        },
        info: {
            type: Object,
            value: {}
        }
    },
    //定义事件方法
    methods: {
        handleOpen() {
            console.log(this.info);
            this.form.supplier = null;
            this.form.supplierId = this.info.supplierId;
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        handleChange(id, supplier) {
            this.form.supplier = supplier;
        },
        submit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    try {
                        this.$emit('onClose')
                        const {
                            id: driverId,
                            supplierName: supplierName1,
                            driverName,
                            driverMobile,
                            carNumberPlate,
                            carBrand,
                            carModel,
                            carType,
                            carColor
                        } = this.info;
                        const {supplierName: supplierName2, id: supplierId} = this.form.supplier;
                        let msg = `<div><p>
是否将以下<span style="color: red">司机</span>和
<span style="color: red">车辆</span>从原供应商
<span style="color: red">${supplierName1}</span>转会至
<span style="color: red">${supplierName2}</span>? </p>
<p>司机信息：${driverName} ${driverMobile}</p>`
                        if (carNumberPlate) msg +=
                            `<p>车辆信息：车牌号：${carNumberPlate},${carBrand}${carModel},
${this.formatCarModel(carType).name},${carColor} </p>
</div>`
                        else msg += '</div>'
                        await this.$confirm(msg, '转会', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                            dangerouslyUseHTMLString: true
                        });
                        this.changeSupplier(driverId, supplierId, carNumberPlate);
                    } catch (e) {
                        console.log(e, 32332);
                    }
                }
            })
        },
        //提交转会
        async changeSupplier(driverId, supplierId, carNumberPlate) {
            this.loadingShow()
            const ret = await this.http('/galaxyDriverApproveApi/driverChangeSupplier', {
                driverId,
                supplierId,
                carNumberPlate
            }, "POST");
            this.loadingHide();
            if (ret.success && ret.data) {
                this.$message.success('司机转会成功！');
                this.$emit('success')
            }
        },
        cancel() {
            this.$emit('onClose')
        }
    }
}
</script>
