// 健康码状态
export const nucleicAcidStatus = [
    {value: 0, label: '阴性'},
    {value: 1, label: '阳性'},
    {value: 2, label: '没有检测'},
]
// 健康码状态
export const healthCodeStatusItem = [
    {value: 0, label: '绿码'},
    {value: 1, label: '黄码'},
    {value: 2, label: '红码'},
]
// 体温状态
export const temperatureStatusItem = [
    {value: 0, label: '正常'},
    {value: 1, label: '发热'},
]
//登记状态
export const isAbnormalItem = [
    {value: 0, label: '正常'},
    {value: 1, label: '异常'},
]
//消毒状态
export const isDisinfectItem = [
    {value: 0, label: '消毒'},
    {value: 1, label: '未消毒'},
]