<style lang="scss" scoped>
    .driver-info-bar {
        margin-bottom: 15px;
        span {
            margin-right: 20px;
        }
    }
</style>
<template>
    <div class="container">
        <p class="driver-info-bar">
            <span>司机姓名：{{info.driverName}}</span>
            <span>手机号：{{info.driverMobile}}</span>
            <span>车辆所属供应商：{{info.supplierName}}</span>
        </p>
        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="">
                <platenumber-autocomplete v-model="form.carNumberPlate"></platenumber-autocomplete>
            </el-form-item>
            <el-form-item label="">
                <seat-model v-model="form.carType" placeholder="车型" is-all></seat-model>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="form.carFlagStatus" placeholder="备案状态">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="(vm,idx) in filingStatus" :key="idx" :label="vm" :value="idx"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="list"
                border
                style="width: 100%">
            <el-table-column
                    prop="carNumberPlate"
                    label="车牌号"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="carOwner"
                    label="所有人"
                    width="65">
            </el-table-column>
            <el-table-column
                    label="车辆品牌"
                    width="90">
                <template slot-scope="scope">
                    <p>
                        {{(scope.row.carBrand || '-') + '' + (scope.row.carModel || '-')}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    label="车型"
                    width="100">
                <template slot-scope="scope">
                    <p>
                        {{formatCarModel(scope.row.carType)}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    prop="carColor"
                    label="颜色"
                    width="50">
            </el-table-column>
            <el-table-column
                    label="基本信息"
                    width="80">
                <template slot-scope="scope">
                    <p>
                        {{formatStatus(scope.row.carAuditStatus).label}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    label="网约信息"
                    width="80">
                <template slot-scope="scope">
                    <p>
                        {{formatStatus(scope.row.carOperationAuditStatus).label}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    label="备案状态"
                    width="80">
                <template slot-scope="scope">
                    <p>
                        {{filingStatus[scope.row.carFlagStatus]}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    label="是否可用"
                    width="50">
                <template slot-scope="scope">
                    <p>
                        {{scope.row.carStatus === 0 ? '否' : '是'}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">绑定</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: right">
            <el-pagination
                    layout="prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script type="text/ecmascript-6">
    import SeatModel from "@/components/seatModel/index";
    import PlatenumberAutocomplete from './platenumber-autocomplete.vue'
    import {getSeatModel} from "@/data/index";
    import {getStatusInfo} from "../service"

    const filingStatus = ['未备案', '已备案', '备案失败'];
    export default {
        //定义模版数据
        data() {
            return {
                filingStatus,
                form: {
                    havingDriverInfo: 0,
                    personalCar: 0,
                    carNumberPlate: '',
                    carStatus: 1,
                    carType: 0,
                    carFlagStatus: null,
                    pageSize: 10,
                    pageIndex: 1
                },
                list: [],//车辆列表
                total: 0
            }
        },
        props: {
            info: {
                type: Object,
                default: () => ({})
            }
        },
        components: {
            SeatModel,
            PlatenumberAutocomplete
        },
        computed: {},
        mounted() {
            this.onSubmit();
        },
        methods: {
            async onSubmit() {
                this.loadingShow();
                let from = {
                    ...this.form
                }
                if (!this.form.carType)
                    delete from.carType
                const ret = await this.http('/galaxyDriverApproveApi/doCarInfoApprovalList', from, 'POST');
                this.loadingHide()
                if (ret.success && ret.data) {
                    this.list = ret.data.data;
                    this.total = ret.data.total;
                }
            },
            formatCarModel(type) {
                if (!type)
                    return ''
                let res = getSeatModel(parseInt(type));
                if (res && res.name)
                    return res.name;
                else
                    return ''
            },
            formatStatus(status) {
                if (!status)
                    return ''
                return getStatusInfo(status);
            },
            async handleClick({id: carId, supplierName: vSupplierName, carNumberPlate, carBrand, carType, carModel, carColor}) {
                const {driverName, driverMobile, supplierName, id: driverId} = this.info;
                try {
                    let msg = `
                    <div style="padding-left: 40px;">
                    <p>为以下司机和车辆建立绑定关系：</p>
                    <p>司机信息：${driverName}，${driverMobile}</p>
                    <p style="padding-left: 70px">${supplierName}</p>
                    <p>车辆信息：${vSupplierName}</p>
                    <p style="padding-left: 70px">${carNumberPlate}</p>
                    <p style="padding-left: 70px">${carBrand}${carModel}，${this.formatCarModel(carType)}，${carColor}车辆</p>
                    </div>
                    `
                    await this.$confirm(msg, '车辆绑定', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        dangerouslyUseHTMLString: true

                    })
                    this.loadingShow()
                    const ret = await this.http('/galaxyDriverApproveApi/driverBindNewCar', {
                        driverId,
                        carId
                    }, 'POST');
                    this.loadingHide();
                    if (ret.success && ret.data) {
                        this.$message.success('绑定成功！');
                        this.$emit('onComplete')
                    }
                }
                catch (e) {
                    console.log(e);

                }
            }
        },
        watch: {
            info: function () {
                this.onSubmit();
            }
        }

    }
</script>
