<template>
    <el-popover placement="left" width="850" trigger="click" @show="getData">
        <div style="display: flex;justify-content: space-between">
            <h3>司机防疫抽查</h3>
            <div style="display: flex">
                <el-button size="small" type="primary" @click="handleAdd" :loading="loading">发起抽查</el-button>
                <el-button style="margin-left: 10px" size="small" type="primary" @click="getData()" :loading="loading">刷新
                </el-button>
            </div>
        </div>
        <el-table :data="result.data" v-loading="loading" max-height="400px">
            <el-table-column width="60" label="序号" align="center" type="index"></el-table-column>
            <el-table-column property="driverName" align="center" label="司机姓名"></el-table-column>
            <el-table-column width="110px" property="driverMobile" align="center" label="手机号"></el-table-column>
            <el-table-column property="status" align="center" label="状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status === 0?'danger':'success'">
                        {{ scope.row.status === 0 ? '待上传' : '已上传' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column width="160" align="center" property="createTime" label="抽查时间">
                <template slot-scope="scope">
                    {{ scope.row.createTime|dateCus }}
                </template>
            </el-table-column>
            <el-table-column width="160" align="center" property="createTime" label="照片">
                <template slot-scope="scope">
                    <el-image style="width:100px" :preview-src-list="[scope.row.photoUrl]" v-if="scope.row.photoUrl"
                              :src="scope.row.photoUrl"/>
                    <p v-else>未上传</p>
                </template>
            </el-table-column>
            <el-table-column width="170" align="center" property="photoTime" label="上传时间">
                <template slot-scope="scope">
                    <p v-if="scope.row.photoTime"> {{ scope.row.photoTime|dateCus }}</p>
                    <p v-else>未上传</p>
                </template>
            </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: flex-end;align-items:center;height: 50px">
            <el-pagination size="mini" @current-change="handleCurrentChange"
                           :current-page="pageIndex"
                           :page-size="pageSize"
                           layout="total, prev, pager, next, jumper"
                           :total="result.total">
            </el-pagination>
        </div>
        <el-button slot="reference" type="text">防疫抽查</el-button>
    </el-popover>
</template>

<script>

import {addDriverCheckWearMaskApi, getDriverCheckWearMaskListByDriverIdApi} from "../../../urls/driverManage";

export default {
    name: "LoginLogLoginLogPopover",
    data() {
        return {
            loading: false,
            pageSize: 10,
            pageIndex: 1,
            result: {
                data: [],
                total: 0
            }
        }
    },
    props: {
        driverInfo: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async getData(index = 1) {
            this.pageIndex = index;
            this.loading = true;
            const ret = await getDriverCheckWearMaskListByDriverIdApi({
                driverId: this.driverInfo.id,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            })
            if (ret.success && ret.data) {
                this.result = ret.data
                this.result.data = this.result.data.map((item) => ({
                    ...this.driverInfo,
                    driverId: this.driverInfo.id,
                    ...item
                }))
                console.log(this.result.data);
            } else this.$message.error(ret.errors[0].message)
            this.loading = false;
        },
        handleCurrentChange(index) {
            this.getData(index);
        },
        async handleAdd() {
            this.loading = true;
            const ret = await addDriverCheckWearMaskApi({
                driverId: this.driverInfo.id
            })
            this.loading = false;
            if (ret.success && ret.data) {
                this.getData(1);
            } else
                this.$message.error(ret.errors[0].message)
        }
    }
}

</script>

<style scoped>

</style>
