import http from "@/common/js/http";

/**
 * 题目列表
 * @param form
 */
export const getLibraryDriverQuestionInfoListApi = async () => {
    return await http(`/galaxyDriverApproveApi/getLibraryDriverQuestionInfoList`);
};

/**
 * 答题详情
 * @param form {questionId:"",status:} 0查询所有司机答题情况 1查询已经答题的司机 2查询未答题的司机
 */
export const getLibraryDriverQuestionBankInfoListApi = async (form) => {
    return await http(`/galaxyDriverApproveApi/getLibraryDriverQuestionBankInfoList`, form, 'POST');
};
