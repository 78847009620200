<style lang="scss" scoped>

</style>
<template>

    <div>
        <div class="product-list-table el-table_border_none">
            <el-table
                    type="index"
                    class="table-info"
                    border
                    :data="fleetList"
                    v-loading="loading"
                    style="width: 100%"
            >
                <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column prop="fleetName" label="车队名" min-width="260"></el-table-column>
                <el-table-column prop="operatorName" label="运营商" width="260"></el-table-column>
                <el-table-column prop="supplierName" label="供应商" width="260"></el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <p>
                            <span v-if="scope.row.useStatus==0">已启用</span>
                            <span v-if="scope.row.useStatus==1">已停用</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="240">
                    <template slot-scope="scope">
                        <div class="operation-btn clear">

                            <el-button type="text" @click="handleSeeFleet(scope.row)">查看</el-button>
                            <el-button type="text"
                                       @click="handleEditFleet(scope.row)">
                                编辑
                            </el-button>
                            <el-button
                                    style="color:#FF3B30"
                                    @click="handleEnable(scope.$index, 1)"
                                    type="text"
                                    v-if="scope.row.useStatus == 0"
                            >停用
                            </el-button>
                            <el-button
                                    @click="handleEnable(scope.$index, 0)"
                                    type="text"
                                    v-if="scope.row.useStatus == 1&&userInfo.userType!=1"
                            >启用
                            </el-button>

                            <el-button type="text" @click="handleShowLog(scope.row)">日志</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let data
            return {
                data
            }
        },
        props: {
            userInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            fleetList: {
                type: Array,
                default: function () {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            handleEditFleet(item){
                this.$emit('update:handleEditFleet', item)
            },
            handleSeeFleet(item){
                this.$emit('update:handleSeeFleet', item)
            }, // 查看
            handleEnable(index, type){
                this.$emit('update:handleEnable', {index, type})
            }, // 启用 停用
            handleShowLog(item){
                this.$emit('update:handleShowLog', item)
            }, // 日志

        },
        //监听模版变量
        watch: {}

    }
</script>

