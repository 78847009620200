<style lang="scss" scoped>
    .eTrip-section-cont {
        background: #fff;
    }

    .fleet-detail-title {
        line-height: 48px;
        padding-left: 20px;
        position: relative;
        margin-bottom: 20px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            bottom: 0;
            background: #F0F2F5;
        }
    }
</style>
<style lang="scss">
    .el-form-item__label {
        padding-right: 0;
    }


</style>
<template>

    <div>
        <el-main class="com-eTrip-section" :class="editType != 2?'section-has-footer':''">
            <div class="eTrip-section-cont">
                <h4 class="fleet-detail-title" style=""><span v-if="editType == 0">创建车队</span><span
                        v-else>{{form.fleetName}}</span>
                </h4>
                <el-form :inline="true" :model="form" size="small" label-width="68px" style="padding-left: 8px">
                    <el-form-item label="运营商：">
                        <operator-info-input
                                :selectOperatorId="selectOperatorId"
                                :is-edit="isOperatorUser"
                                ref="operatorInfoId"
                                @update:getSelectOperator="getSelectOperator">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item label="供应商：">
                        <supplier-info-input
                                :selectSupplierId="selectSupplierId"
                                :operatorInfo="form.operatorInfo"
                                :is-edit="isSupplierUser"
                                @update:getSelectSupplier="getSelectSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="车队名：">
                        <el-input v-model="form.fleetName" placeholder="输入车队名" :disabled="isEdit"></el-input>
                    </el-form-item>
                </el-form>
                <fleet-info-list
                        style="padding-left: 20px"
                        :addType="1"
                        :fleetInfoList="form.driverInfoList"
                        :is-edit="isEdit"
                        @update:getAddDriver="handleAddDriver"
                        @update:getDelete="handleDelete">
                </fleet-info-list>
                <fleet-info-list
                        style="padding-left: 20px"
                        :addType="2"
                        :fleetInfoList="form.carInfoList"
                        :is-edit="isEdit"
                        @update:getAddDriver="handleAddDriver"
                        @update:getDelete="handleDelete">
                </fleet-info-list>
            </div>
        </el-main>
        <add-info-dialog
                :addType="1"
                :addDialogShow="addDialogShow1"
                :supplierId="selectSupplierId"
                ref="supplierDriverListRef"
                @update:getCancel="handleCancel"
                @update:getAddSelect="handleAddSelect">
        </add-info-dialog>
        <add-info-dialog
                :addType="2"
                :addDialogShow="addDialogShow2"
                :supplierId="selectSupplierId"
                ref="supplierCarListRef"
                @update:getCancel="handleCancel"
                @update:getAddSelect="handleAddSelect">
        </add-info-dialog>
        <delete-info-dialog
                :addType="1"
                :delInfoDialog="delDriverInfoDialog"
                :delDialogShow="delDialogShow1"
                :orderNotFinished="orderNotFinished"
                @update:getCancel="handleCancel1"
                @update:getDelSelect="handleDelSelect"
        >
        </delete-info-dialog>
        <delete-info-dialog
                :addType="2"
                :delInfoDialog="delCarInfoDialog"
                :delDialogShow="delDialogShow2"
                :orderNotFinished="orderNotFinished"
                @update:getCancel="handleCancel1"
                @update:getDelSelect="handleDelSelect">
        </delete-info-dialog>

        <!--弹窗二次确认弹框 Start-->
        <del-and-add-info-dialog
                :show="delAndAddShow"
                :delInfo="delDriverAndCarInfo"
                :addInfo="addDriverAndCarInfo"
                @update:getCancelBtn="delAddCancelBtn"
                @update:getSubmitBtn="delAddSubmitBtn">
        </del-and-add-info-dialog>
        <!--弹窗二次确认弹框 End-->

        <el-footer class="com-eTrip-footer" height="64px" v-if="editType != 2">
            <div class="eTrip-footer-cont" style="text-align: right">
                <el-button size="small" @click="handleCancelFoot">取 消</el-button>
                <el-button size="small" type="primary" @click="handleSubmit">确 定</el-button>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
    import fleetInfoList from "./components/fleetInfoList/index"
    import addInfoDialog from "./components/addInfoDialog/index"
    import deleteInfoDialog from "./components/deleteInfoDialog/index"
    import operatorInfoInput from "../../../components/operatorInfoInput/index"
    import supplierInfoInput from "../../../components/supplierInfoInput/index"
    import delAndAddInfoDialog from "./components/delAndAddInfoDialog/index"

    import fleetDetailServer from '../../../urls/fleetDetailUrls'

    export default {
        //定义模版数据
        name: "fleetDetail",
        data() {
            return {
                selectOperatorId: '',
                selectSupplierId: '',
                isEdit: false,
                isOperatorUser: false,
                isSupplierUser: false,
                form: {
                    operatorInfo: {},
                    supplierInfo: {},
                    fleetName: '',
                    driverInfoList: [],
                    carInfoList: [],
                },
                addDialogShow1: false,
                addDialogShow2: false,
                // type: '',  // 1 司机  2 车辆
                delDriverInfoDialog: {},
                delDialogShow1: false,
                delCarInfoDialog: {},
                delDialogShow2: false,
                orderNotFinished: null, // 司机或车辆未完成订单
                delDriverAndCarInfo: {
                    driverList: [],
                    carList: []
                },  // 删除司机车辆push
                addDriverAndCarInfo: {
                    driverList: [],
                    carList: []
                },  // 新增司机车辆push
                delAndAddShow: false, // 弹窗二次确认弹框
            }
        },
        components: {
            fleetInfoList,
            addInfoDialog,
            operatorInfoInput,
            supplierInfoInput,
            deleteInfoDialog,
            delAndAddInfoDialog
        },
        //计算属性
        computed: {
            driverFleetId() {
                return this.$route.params.id;
            },
            editType() {
                return this.$route.params.editType;

            },
            userInfo() {
                return this.$store.state.loginUserInfo;
            },
        },

        //主件被加载完成
        mounted: function () {
            if (this.editType == 2) {
                this.isEdit = true;
                this.isOperatorUser = true;
                this.isSupplierUser = true
            } else {
                this.isEdit = false;
                this.isOperatorUser = false;
                this.isSupplierUser = false
            }
            if (this.userInfo.userType == 2) {
                this.selectOperatorId = this.userInfo.refId;
                this.form.operatorInfo.id = this.userInfo.refId;
                this.isOperatorUser = true
            }

            if (this.userInfo.userType == 1) {
                this.getBySupplierId(this.userInfo.refId)
                this.selectSupplierId = this.userInfo.refId;
                this.isOperatorUser = true;
                this.isSupplierUser = true
            }
            if (this.$route.params.id != 0) {
                this.getDriverFleetDetail()
            }
        },
        //定义事件方法
        methods: {
            async getBySupplierId(supplierId) {
                let res = await this.http(`/galaxyOperator/getBySupplierId/${supplierId}`)
                this.selectOperatorId = res.data.operatorId
                this.form.operatorInfo.id = res.data.operatorId
                console.log(res, "2222");
            },
            async getDriverFleetDetail() {
                let res = await fleetDetailServer.driverFleetDetail(this, this.driverFleetId);
                if (res.success && res.data) {
                    let data = res.data;
                    data.userDriverInfoDtoList.forEach(item => {
                        item.isOriginalData = true
                    })
                    data.userCarInfoDtoList.forEach(item => {
                        item.isOriginalData = true
                    })
                    this.form.fleetName = data.fleetName;
                    this.form.driverInfoList = data.userDriverInfoDtoList;
                    this.form.carInfoList = data.userCarInfoDtoList;
                    this.selectOperatorId = data.operatorId;
                    this.form.operatorInfo = {
                        id: data.operatorId
                    }
                    this.selectSupplierId = data.supplierId;
                }
            },

            getSelectOperator(item) {  // 运营商
                // this.form.operatorInfo = item
                this.form.operatorInfo = item;
            },
            getSelectSupplier(item) {
                this.form.supplierInfo = item;
                this.selectSupplierId = item.id;
            },


            handleAddDriver(type) {
                // this.addInfo.type = type;
                switch (type) {
                    case 1:
                        this.$refs.supplierDriverListRef.handleInfoList(1);
                        this.addDialogShow1 = true;
                        break;
                    case 2:
                        this.$refs.supplierCarListRef.handleInfoList(2);
                        this.addDialogShow2 = true;
                        break;
                }
            },
            handleCancel(item) {
                console.log(item);
                switch (item.type) {
                    case 1:
                        this.addDialogShow1 = item.show;
                        break;
                    case 2:
                        this.addDialogShow2 = item.show;
                        break;
                }

            },
            handleCancel1(item) {
                console.log(item);
                switch (item.type) {
                    case 1:
                        this.delDialogShow1 = item.show;
                        break;
                    case 2:
                        this.delDialogShow2 = item.show;
                        break;
                }

            },
            handleDelete(info) {
                let delInfo = info.deleteInfo,
                    listInfo = [];
                switch (info.type) {
                    case 1:
                        listInfo = this.form.driverInfoList;
                        this.delDialogShow1 = true
                        break;
                    case 2:
                        listInfo = this.form.carInfoList;
                        this.delDialogShow2 = true
                        break;
                }
                listInfo.forEach(item => {
                    if (item.id == delInfo.id) {
                        switch (info.type) {
                            case 1:
                                this.delDriverInfoDialog = item;
                                break;
                            case 2:
                                this.delCarInfoDialog = item;
                                break;
                        }
                        // listInfo.splice(item, 1)
                    }
                });
                this.handleDriverAndCar(info.type)
            },
            // 根据司机信息Id车牌号查询订单信息   {"carNumberPlate": "string","driverGuiderNo": "string"}
            async handleDriverAndCar(type) {
                let form = {};
                switch (type) {
                    case 1:
                        form.driverGuiderNo = this.delDriverInfoDialog.id;
                        break;
                    case 2:
                        form.carNumberPlate = this.delCarInfoDialog.carNumberPlate;
                        break;
                }
                let res = await fleetDetailServer.selectOrderInfoByDriverAndCar(this, form);
                if (res.success && res.data) {
                    this.orderNotFinished = res.data;
                    console.log(this.orderNotFinished);
                }
            },

            handleDelSelect(info) {
                let delDriverList = [],
                    delCarList = [],
                    addDriverList = this.addDriverAndCarInfo.driverList,
                    addCarList = this.addDriverAndCarInfo.carList;

                let driverInfoList = this.form.driverInfoList;
                let carInfoList = this.form.carInfoList;
                let carList = [], driverList = [];
                switch (info.type) {
                    case 1:
                        driverInfoList.forEach((item, index) => {
                            if (info.delInfo.id == item.id) {
                                if (item.isOriginalData) {
                                    driverList.push(item)
                                }
                                this.$refs.supplierDriverListRef.delFleetId(item.id);
                                addDriverList.forEach((list, idx) => {
                                    if (list.id == info.delInfo.id) {
                                        addDriverList.splice(idx, 1)
                                    }
                                })
                                driverInfoList.splice(index, 1)

                            }
                        })
                        delDriverList = [...this.delDriverAndCarInfo.driverList, ...driverList];
                        this.delDriverAndCarInfo.driverList = delDriverList;
                        this.delDialogShow1 = false;
                        break;
                    case 2:
                        carInfoList.forEach((item, index) => {
                            if (info.delInfo.id == item.id) {
                                if (item.isOriginalData) {
                                    carList.push(item)
                                }
                                this.$refs.supplierCarListRef.delFleetId(item.id);
                                addCarList.forEach((list, idx) => {
                                    if (list.id == info.delInfo.id) {
                                        addCarList.splice(idx, 1)
                                    }
                                })
                                this.form.carInfoList.splice(index, 1)
                            }
                        })
                        delCarList = [...this.delDriverAndCarInfo.carList, ...carList];
                        this.delDriverAndCarInfo.carList = delCarList;
                        this.delDialogShow2 = false;
                        break;
                }
            },

            handleAddSelect(info) {
                let addDriverList = [],
                    addCarList = [],
                    driverInfoList = this.form.driverInfoList,
                    carInfoList = this.form.carInfoList,
                    driverList = [],
                    carList=[];
                switch (info.type) {
                    case 1:
                        driverInfoList.forEach(item => {
                            if (item.isOriginalData) {
                                driverList.push(item)
                            }
                        })
                        this.form.driverInfoList = [...driverList, ...info.selectList];
                        addDriverList = [...info.selectList];
                        this.addDriverAndCarInfo.driverList = addDriverList;
                        this.addDialogShow1 = false;
                        break;
                    case 2:
                        carInfoList.forEach(item => {
                            if (item.isOriginalData) {
                                carList.push(item)
                            }
                        })
                        this.form.carInfoList = [...carList, ...info.selectList];
                        addCarList = [...info.selectList];
                        this.addDriverAndCarInfo.carList = addCarList;
                        this.addDialogShow2 = false;
                        break;
                }
            },
            delAddCancelBtn(show) {
                this.delAndAddShow = show;
            },  // 新增删除二次确认取消按钮
            delAddSubmitBtn(show) {
                this.submitBtn()
                this.delAndAddShow = show;
            },  // 新增删除二次确认 确认按钮
            handleSubmit() {
                if (!this.form.operatorInfo.id) return this.$message.error('请填写运营商名称');
                if (!this.form.supplierInfo.id) return this.$message.error('请填写供应商名称');
                if (!this.form.fleetName) return this.$message.error('请填写车队名');
                // if (!this.form.driverInfoList.length > 0) return this.$message.error('请选择司机');
                // if (!this.form.carInfoList.length > 0) return this.$message.error('请选择车辆');
                this.delAndAddShow = true;

            },
            async submitBtn() {
                this.loadingShow();
                let res = '',
                    messageTitle = '';

                let {
                        fleetName,
                        operatorInfo,
                        supplierInfo,
                        carInfoList,
                        driverInfoList
                    } = this.form,
                    form = {
                        fleetName,
                        operatorId: operatorInfo.id,
                        supplierId: supplierInfo.id
                    };

                let carInfoIdList = [],
                    driverInfoIdList = [];
                carInfoList.forEach(item => {
                    carInfoIdList.push(item.id)
                });
                form.carIdList = JSON.parse(JSON.stringify(carInfoIdList));
                driverInfoList.forEach(item => {
                    driverInfoIdList.push(item.id)
                })
                form.driverIdList = JSON.parse(JSON.stringify(driverInfoIdList));

                switch (JSON.parse(this.editType)) {
                    case 0:  // 创建
                        res = await fleetDetailServer.driverFleetCreate(this, form);
                        messageTitle = '车队创建'
                        break;
                    case 1:  // 编辑
                        form.id = this.driverFleetId
                        res = await fleetDetailServer.driverFleetUpdate(this, form);
                        messageTitle = '修改车队'
                }
                if (res && res.success) {
                    this.$message({
                        message: `${messageTitle}成功`,
                        type: 'success'
                    });
                    this.$router.push('/fleetList')
                }
                // else {
                //     this.$message.error(`${messageTitle}失败`);
                // }
                this.loadingHide();

            },
            handleCancelFoot() {
                this.$store.commit('delete_tabs', this.$route.path);
                this.$router.push('/fleetList')
            }

        },
        //监听模版变量
        watch: {}

    }
</script>

